import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";

import { StrategyContext } from "../../Contexts/StrategyContext";
import { useContext, useState, useRef } from "react";
import { AuthContext } from "../../Contexts/Auth";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 5,
  textAlign: "center",
  borderRadius: "5px",
};

export default function SaveStrategyButton(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [upgradeErr, setupgradeErr] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { StrategyFor } = useContext(StrategyContext);
  const { user, checkPro } = useContext(AuthContext);
  const strategyName = useRef(null);

  const saveStrategy = async () => {
    if (user.pro && checkPro(user?.pro_expiry)) {
      setupgradeErr(false);
      const scripts = [];
      await StrategyFor.forEach((script, index) => {
        if (props.selectUserStrategy === null) {
          scripts.push({
            strike: script.StrikePrice,
            expiry: script.Expiry,
            type: script.Type,
            direction: script.Direction,
            lots: script.lots,
            entry: script.Premium,
          });
        } else {
          if (script.PositionsInstanceId === undefined) {
            scripts.push({
              strike: script.StrikePrice,
              expiry: script.Expiry,
              type: script.Type,
              direction: script.Direction,
              lots: script.lots,
              entry: script.Premium,
              PositionsInstanceId: StrategyFor[0].PositionsInstanceId,
            });
            StrategyFor[index].PositionsInstanceId =
              StrategyFor[0].PositionsInstanceId;
            StrategyFor[index].booked = 0;
            StrategyFor[index].unrealized = 0;
            StrategyFor[index].currentPrice = script.Premium;
          }
        }
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      };
      if (props.selectUserStrategy !== null) {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/addScript`,
            {
              scripts: scripts,
            },
            { headers: headers }
          )
          .then(() => {
            props.setCounterSaveVisible(false);
            props.fetchSavedStrategy(user);
          })
          .catch((error) => {
            console.log(error); // Handle Signup Error
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/createposition`,
            {
              strategyName: strategyName.current.value,
              scripts: scripts,
            },
            { headers: headers }
          )
          .then(() => {
            props.fetchSavedStrategy(user);
            handleClose();
          })
          .catch((error) => {
            console.log(error); // Handle Signup Error
          });
      }
    } else {
      setupgradeErr(true);
    }
  };

  return (
    <div>
      <button
        class="rounded-lg px-4 py-1.5 border-2 border-[#68cae3] text-[#68cae3] hover:bg-[#68cae3] hover:text-white duration-300"
        onClick={() =>
          Object.keys(user).length > 0
            ? props.selectUserStrategy === null
              ? handleOpen()
              : saveStrategy()
            : navigate("/login")
        }
        type="button"
      >
        Save Strategy
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 className="text-xl font-semibold italic text-black">
            Enter Strategy Name
          </h2>
          <input
            type="text"
            ref={strategyName}
            className="outline-none border-[1px] border-black/30 px-4 py-1.5 mt-4 text-center placeholder:text-center rounded-md focus:shadow-md"
            placeholder="Your Custom Strategy Name"
          />
          <button
            onClick={saveStrategy}
            className="bg-[#68cae3] text-white mt-4 px-8 py-2.5 rounded-md font-semibold "
          >
            Save Strategy
          </button>
          {upgradeErr && (
            <div className="flex flex-col">
              <div className="text-red-500 italic flex flex-col items-center my-3">
                {" "}
                <ExclamationCircleIcon className="w-14 h-14" />
                <p>Only Pro Members can save the strategy</p>
              </div>

              <div className="flex gap-2 justify-center items-center">
                {" "}
                <Link to="/profile">
                  <div>
                    <p className="text-[#68cae3] inline-flex hover:text-white border border-[#68cae3] hover:bg-[#68cae3] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center gap-1 cursor-pointer  dark:border-[#68cae3] dark:text-[#68cae3] dark:hover:text-white dark:hover:bg-[#68cae3] dark:focus:ring-[#68cae3]">
                      {" "}
                      Upgrade Pro
                    </p>
                  </div>
                </Link>
                <Link to="/profile">
                  <div>
                    <p className="text-white inline-flex hover:text-white border gradientBgAnimated focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center gap-1 cursor-pointer  dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800">
                      {" "}
                      Activate Free Trail
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
