import React, { useContext } from "react";
import Navbar from "./Components/Navbar";
import SelectorPane from "./Components/SelectorPane";
import AnalyzerPane from "./Components/AnalyzerPane";

import { AuthContext } from "./Contexts/Auth";
import { Navigate, Route, Routes } from "react-router-dom";

import Login from "./Components/Pages/Login";
import SignUp from "./Components/Pages/Signup";
import { Toaster } from "react-hot-toast";
import UserProfilePage from "./Components/Pages/Profile";

function App() {
  const { user } = useContext(AuthContext);

  return (
    <>
      <Navbar />
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <Routes>
        <Route
          path="/"
          element={
            <div className="md:px-[10%] pb-4  pt-16 bg-gray-100 ">
              <div className="flex flex-col md:items-start items-center md:flex-row pt-4 w-full ">
                <div className="md:basis-1/3 ">
                  <SelectorPane />
                </div>
                <div className="md:basis-2/3 w-full ">
                  <AnalyzerPane />
                </div>
              </div>
            </div>
          }
        />
        <Route
          path="/signup"
          element={user.name ? <Navigate to="/" /> : <SignUp />}
        />
        <Route
          path="/login"
          element={user.name ? <Navigate to="/" /> : <Login />}
        />
        <Route
          path="/profile"
          element={!user.name ? <Navigate to="/" /> : <UserProfilePage />}
        />
      </Routes>
    </>
  );
}

export default App;
