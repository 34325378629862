import Summary from "./AnalyzerPaneComponents/Summary";
import Visualize from "./AnalyzerPaneComponents/Visualize";
import { StrategyContext } from "../Contexts/StrategyContext";
import { DataContext } from "../Contexts/DataContext";
import { useContext, useEffect, useState } from "react";
import { OptionPayoff, priceRange, summaryData } from "../CalculationFunctions";
// import GreeksTable from "./AnalyzerPaneComponents/GreeksTable";
import Nodata from "./Nodata";

function AnalyzerPane() {
  const { StrategyFor } = useContext(StrategyContext);
  const [selectedStrategy, setSelectedStrategy] = useState([]);
  const [totalPnl, setTotalpnl] = useState();
  useEffect(() => {
    const selectedStrategyList = StrategyFor.filter(
      (item) => item.check !== false && !item?.exit
    );
    setSelectedStrategy(selectedStrategyList);
  }, [StrategyFor]);
  // const Expiry =
  //   selectedStrategy.length > 0
  //     ? selectedStrategy.reduce((r, o) => (o.Expiry < r.Epiry ? o : r)).Expiry // Modify this may not be working as Intended
  //     : null;
  const Delta = selectedStrategy.reduce(
    (acc, curr) =>
      acc + curr.Delta * curr.lots * (curr.Direction === "sell" ? -1 : 1),
    0
  );
  const Theta = selectedStrategy.reduce(
    (acc, curr) =>
      acc + curr.Theta * curr.lots * (curr.Direction === "sell" ? -1 : 1),
    0
  );
  const Gamma = selectedStrategy.reduce(
    (acc, curr) =>
      acc + curr.Gamma * curr.lots * (curr.Direction === "sell" ? -1 : 1),
    0
  );
  const Vega = selectedStrategy.reduce(
    (acc, curr) =>
      acc + curr.Vega * curr.lots * (curr.Direction === "sell" ? -1 : 1),
    0
  );
  const Rho = selectedStrategy.reduce(
    (acc, curr) => acc + curr.Rho * curr.lots,
    0
  );

  const { Price } = useContext(DataContext);
  const nearPrice = Math.floor(Price / 500) * 500;
  const payOffMeta = {
    price: Price,
    priceRange: priceRange(nearPrice, 20, StrategyFor),
  };
  const payOffData = OptionPayoff(selectedStrategy, payOffMeta);

  const chartData = payOffData.meta.priceRange.map((item, index) => {
    return { price: item, pnl: payOffData.payoff[index] };
  });

  // console.log(StrategyFor.reduce((acc, item) => acc + item.booked, 0));

  return (
    <div className="flex flex-col px-1 md:px-0 md:ml-1 pt-2">
      <div className="">
        {payOffData.options.length > 0 ? (
          <Summary
            data={
              StrategyFor[0]?.booked
                ? summaryData(
                  chartData.map((item) => ({
                    price: item.price,
                    pnl:
                      item.pnl -
                      StrategyFor.reduce((acc, item) => acc + item.booked, 0),
                  }))
                )
                : summaryData(chartData)
            }
            Delta={Delta}
            Theta={Theta}
            Gamma={Gamma}
            Vega={Vega}
            Rho={Rho}
          />
        ) : (
          <Nodata />
        )}
      </div>
      <div>
        {payOffData.options.length > 0 && (
          <Visualize chartData={chartData} /> // Earliest Expiry Provided
        )}
      </div>
    </div>
  );
}

export default AnalyzerPane;
