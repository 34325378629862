import React from "react";

const Nodata = () => {
  return (
    <div className="flex flex-col justify-center items-center h-[500px]">
      <p className="py-5 text-black italic ">
        {" "}
        No Strategy Selected Please Select Your Strategy
      </p>
      <img src="/stikebet_logo.png" width="200px" height="200px" alt="" className="" />
      <p className="py-5 text-black italic ">
        Build An Custom Strategy And Save It !
      </p>
    </div>
  );
};

export default Nodata;
