import { Link } from "react-router-dom";
import { FaRegEnvelope } from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import { AuthContext } from "../../Contexts/Auth";
import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Blocks } from "react-loader-spinner";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { LockClosedIcon, XMarkIcon } from "@heroicons/react/24/solid";
import Modal from "@mui/material/Modal";

export default function Login() {
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const [password, setPassword] = useState(null);
  const emailChangeRef = useRef();
  const otpRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const handleOpen = () => setOpen(true);
  const navigate = useNavigate();
  const { userLogin } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/signin`, {
        email: email,
        nakedpassword: password,
      })
      .then((response) => {
        userLogin({
          token: response.data.token,
          name: response.data.name,
          email: response.data.email,
          pro: response.data.pro,
          trialAvailble: response.data.trialAvailble,
          passCheck: response.data.passCheck,
          pro_expiry: response.data.pro_expiry,
        });
        toast.dismiss();
        navigate("/");
        setLoading(false);
        toast.success("Login Successful");
      })
      .catch((error) => {
        setLoading(false);
        toast.dismiss();
        console.log(error); // Handle Login Error
        if (error.response.status === 400) {
          toast.error("Please Enter Correct Password");
        } else if (error.response.status === 404) {
          toast.error("User Not Found");
        } else if (error.response.status === 300) {
          toast.error("Verify your Email");
          navigate("/signup", {
            state: {
              emailVerification: true,
              email: email,
            },
          });
        } else {
          toast.error("Something Went Wrong");
        }
      });
  };

  const googleHandleSubmit = async (event) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/googlelogin`, {
        event,
      })
      .then((response) => {
        userLogin({
          token: response.data.token,
          name: response.data.name,
          email: response.data.email,
          pro: response.data.pro,
          trialAvailble: response.data.trialAvailble,
          passCheck: response.data.passCheck,
          pro_expiry: response.data.pro_expiry,
        });
        navigate("/");
        toast.dismiss();
        toast.success("Login Successful");
      })
      .catch((error) => {
        toast.dismiss();
        console.log(error); // Handle Login Error
        toast.error("User Login Failed");
      });
  };

  const handlePasswordChange = async (event) => {
    event.preventDefault();

    if (emailChangeRef.current.value.includes("@")) {
      setEmailLoading(true);
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/changePassword`, {
          email: emailChangeRef.current.value,
        })
        .then((response) => {
          setEmailLoading(false);
          setEmailSent(true);
          setEmail(emailChangeRef.current.value);
        })
        .catch((error) => {
          toast.dismiss();
          console.log(error); // Handle Login Error
          toast.error("Something Went Wrong");

          setEmailLoading(false);
        });
    } else {
      toast.dismiss();
      toast.error("Please Enter Valid Email Address");
    }
  };

  const handlePasswordChangeRequest = async () => {
    if (passwordRef.current.value.length > 8) {
      if (confirmPasswordRef?.current?.value === passwordRef?.current?.value) {
        // Change password api;
        await axios
          .post(`${process.env.REACT_APP_API_URL}/api/verifyOtp`, {
            otp: otpRef.current.value,
            password: passwordRef.current.value,
            email: email,
          })
          .then((response) => {
            passwordRef.current.value = "";
            confirmPasswordRef.current.value = "";

            setEmailLoading(false);
            setEmailSent(false);
            toast.success("Password Changed, Please Login");
            setEmail(null);
            setOpen(false);
          })
          .catch((error) => {
            console.log(error);
            toast.error("Otp Incorrect or Otp Expired");
          });
      } else {
        toast.dismiss();
        toast.error("Passwords do not Match");
      }
    } else {
      toast.dismiss();
      toast.error("Password length cannot be less then 8");
    }
  };

  const handleClose = () => {
    setEmailLoading(false);
    setEmailSent(false);

    setOpen(false);
  };

  return (
    <>
      <motion.div className="flex flex-col items-center justify-center min-h-[95vh] py-2 overflow-y-hidden bg-gray-100 mt-10 ">
        <div className="flex flex-col items-center justify-center flex-1 w-full p-2 text-center md:px-20">
          <motion.div
            initial={{
              x: -500,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1,
            }}
            className="flex flex-col w-full bg-white rounded-sm shadow-xl md:max-w-4xl md:flex-row "
          >
            <div className="md:w-2/5 px-12 text-white bg-[#68cae3] rounded-tr-sm rounded-br-sm py-10  md:hidden ">
              <h2 className="mb-2 text-3xl font-bold ">Hello, Friend!</h2>
              <div className="inline-block w-10 mb-2 border-2 border-white"></div>
              <p className="mb-8">
                Fill up the personal information and start journey with us.{" "}
              </p>
              <Link
                to="/signup"
                className="inline-block px-12 py-2 font-semibold transition-all duration-300 ease-in-out border-2 border-white rounded-lg hover:border-black hover:bg-white hover:text-[black] "
              >
                Signup
              </Link>
            </div>
            <div className="p-5 rounded-tl-sm rounded-bl-sm md:w-3/5">
              <div className="text-lg font-bold tracking-wide text-left">
                <img
                  src="/PNSB.png"
                  alt="STRIKEBET"
                  className="w-[100px]"
                />
              </div>
              <div className="py-10 ">
                <div className="flex flex-col justify-center items-center">
                  <h2 className="mb-2 text-3xl font-bold ">Login to Account</h2>
                  <div className="inline-block w-10 mb-2 border-2 border-black"></div>
                </div>
                <div className="inline-flex items-center gap-3 px-6 pt-2 font-semibold my-2 transition-all duration-300 ease-in-out  rounded-sm    hover:text-[black] ">
                  <GoogleLogin
                    onSuccess={async (credentialResponse) => {
                      const decode = jwt_decode(credentialResponse.credential);
                      googleHandleSubmit(decode);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>

                <p className="text-sm text-gray-500 capitalize py-2 font-semibold">
                  Or use Email Id
                </p>

                <div className="flex flex-col items-center ">
                  <div className="flex items-center w-64 p-2 mb-4 bg-gray-100">
                    <FaRegEnvelope className="m-2 text-gray-500" />
                    <input
                      type="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      label="Email Address"
                      autoComplete="email"
                      placeholder="Email"
                      required
                      className="flex-1 bg-transparent outline-none"
                    />
                  </div>
                  <div className="flex items-center w-64 p-2 bg-gray-100">
                    <MdLockOutline className="m-2 text-gray-500" />
                    <input
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      name="password"
                      label="Password"
                      placeholder="Password"
                      id="password"
                      autoComplete="new-password"
                      required
                      className="flex-1 bg-transparent outline-none"
                    />
                  </div>
                  <div className="flex items-center w-64 py-1">
                    <p
                      className="text-sm text-gray-500 underline cursor-pointer"
                      onClick={handleOpen}
                    >
                      Forgot Password?
                    </p>
                  </div>
                  {loading ? (
                    <button className="inline-block px-12 py-2 font-semibold my-2 transition-all duration-300 ease-in-out border-2 rounded-sm border-[#68cae3]  hover:border-black hover:text-[black] ">
                      <Blocks
                        height="25"
                        width="50"
                        color="#68cae3"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </button>
                  ) : (
                    <button
                      onClick={handleSubmit}
                      className="inline-block px-12 py-2 font-semibold my-2 duration-300 ease-in-out border-2 rounded-lg border-[#68cae3]  text-[#68cae3] hover:bg-[#68cae3] transition hover:text-white"
                    >
                      Login
                    </button>
                  )}
                </div>
              </div>
            </div>
            <motion.div
              initial={{
                y: -200,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                duration: 1,
              }}
              className="md:w-2/5 hidden px-12 text-white gradientBg rounded-tr-sm rounded-br-sm py-36  md:inline-block "
            >
              <h2 className="mb-2 text-3xl font-bold ">Hello, Friend!</h2>
              <div className="inline-block w-10 mb-2 border-2 border-white"></div>
              <p className="mb-8">
                Fill up the personal information and start journey with us.{" "}
              </p>
              <Link
                to="/signup"
                className="inline-block px-12 py-2 font-semibold transition-all duration-300 ease-in-out border-2 border-white rounded-lg hover:border-black hover:bg-white hover:text-[black] "
              >
                Signup
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </motion.div>
      <Modal open={open} onClose={(e) => { }}>
        <div className="absolute outline-none w-[600px]  shadow-lg bg-[whitesmoke] border border-black/20  top-[20%] left-[50%] -translate-x-[50%]">
          <div className="flex relative w-full h-full flex-col p-6  items-center">
            <p className="text-black font-semibold capitalize text-xl italic ">
              Reset Your Password
            </p>
            <div className="border mb-4 border-[#68cae3] w-[20%] mt-0.5" />
            <LockClosedIcon
              color="black"
              className="w-[150px] h-[150px] text-gray-700 mb-3"
            />
            {emailSent ? (
              <div>
                <div className="w-full">
                  <p className="text-gray-600 italic mb-2 text-center">
                    Enter The Otp Sent To Email
                  </p>
                  <div className="w-full flex justify-center items-center">
                    <input
                      type="text"
                      ref={otpRef}
                      placeholder="Enter Otp"
                      className="px-3 py-1.5 border  text-gray-600 border-black/30 focus:text-black placeholder:text-gray-400 outline-none placeholder:font-semibold placeholder:text-sm placeholder:text-center rounded-md shadow-lg mb-3"
                    />
                  </div>

                  <p className="text-gray-600 italic mb-2 text-center">
                    Enter Your New Password
                  </p>
                  <div className="flex gap-4 items-center ">
                    <input
                      type="password"
                      ref={passwordRef}
                      placeholder=" New Password"
                      className="px-3 py-1.5 border text-gray-600 border-black/30 focus:text-black placeholder:text-gray-400 outline-none placeholder:font-semibold placeholder:text-sm placeholder:text-center rounded-md shadow-lg mb-3"
                    />
                    <input
                      type="password"
                      ref={confirmPasswordRef}
                      placeholder="Confirmation Password"
                      className="px-3 py-1.5 border text-gray-600 border-black/30 focus:text-black placeholder:text-gray-400 outline-none placeholder:font-semibold placeholder:text-sm placeholder:text-center rounded-md shadow-lg mb-3"
                    />
                  </div>
                  <button
                    onClick={handlePasswordChangeRequest}
                    className="block mx-auto px-12 py-2 font-semibold my-2 transition-all duration-300 ease-in-out border-2 rounded-sm border-[#68cae3]  hover:border-black hover:text-[black] "
                  >
                    Change Password
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col w-full h-full items-center ">
                <p className="text-black text-xl mb-3 font-semibold">
                  Forgot Password?{" "}
                </p>
                <p className="text-gray-600 italic mb-2">
                  {" "}
                  Enter Your Email to Reset Password
                </p>
                <form
                  onSubmit={handlePasswordChange}
                  className="flex flex-col justify-center items-center"
                >
                  <div>
                    <input
                      type="text"
                      ref={emailChangeRef}
                      placeholder="Enter Your Mail"
                      className="px-3 py-1.5 border text-gray-600 border-black/30 focus:text-black placeholder:text-gray-400 outline-none placeholder:font-semibold placeholder:text-sm placeholder:text-center rounded-md shadow-lg mb-3"
                    />
                  </div>
                  <p className="text-sm italic text-gray-500 my-2">
                    Check Your Spam/Junk folder in Email
                  </p>
                  {emailLoading ? (
                    <button className="inline-block px-12 py-2 font-semibold my-2 transition-all duration-300 ease-in-out border-2 rounded-sm border-[#68cae3]  hover:border-black hover:text-[black] ">
                      <Blocks
                        height="25"
                        width="50"
                        color="#68cae3"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="inline-block px-12 py-2 font-semibold my-2 transition-all duration-300 ease-in-out border-2 rounded-lg border-[#68cae3] hover:text-white hover:bg-[#68cae3]"
                    >
                      Submit
                    </button>
                  )}
                </form>
              </div>
            )}
            <button className="">
              <XMarkIcon
                className="absolute w-8 h-8 text-white right-0 top-0 bg-black rounded-lg shadow-lg font-semibold cursor-pointer hover:text-red-500 "
                onClick={handleClose}
              />
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
