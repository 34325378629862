function GreeksTable(props) {
  return (
    <>
      <div>
        <h1 className="text-center font-semibold">Greek Table</h1>
      </div>
      <div className="flex flex-col basis-1/2 justify-center italic !text-gray-500">
        <div className="flex justify-between border-b-[1px] py-2">
          <h1 className="text-sm font-semibold text-black ">Delta</h1>
          <p className="text-sm  text-gray-700">
            {Math.round((props.props.Delta + Number.EPSILON) * 100) / 100}
          </p>
        </div>
        <div className="flex justify-between border-b-[1px] py-2">
          <h1 className="text-sm font-semibold text-black ">Theta</h1>
          <p className="text-sm  text-gray-700">
            {" "}
            {Math.round((props.props.Theta + Number.EPSILON) * 100) / 100}
          </p>
        </div>
        <div className="flex justify-between border-b-[1px] py-2">
          <h1 className="text-sm font-semibold text-black ">Gamma</h1>
          <p className="text-sm  text-gray-700">
            {Math.round((props.props.Gamma + Number.EPSILON) * 100) / 100}
          </p>
        </div>
        <div className="flex justify-between border-b-[1px] py-2">
          <h1 className="text-sm font-semibold text-black ">Vega</h1>
          <p className="text-sm  text-gray-700">
            {Math.round((props.props.Vega + Number.EPSILON) * 100) / 100}
          </p>
        </div>
      </div>
    </>
  );
}

export default GreeksTable;
