import React, { useContext, useState } from "react";
import { AuthContext } from "../../Contexts/Auth";
import Avatar from "react-avatar";
import email from "../../assets/email.png";

import {
  CheckCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useRef } from "react";
import basic from "../../assets/basic.png";
import lifetime from "../../assets/lifetime.png";
import pro from "../../assets/pro.png";
import payment from "../../assets/payment.png";
import { DataContext } from "../../Contexts/DataContext";
import axios from "axios";
import { toast } from "react-hot-toast";
const UserProfilePage = () => {
  const { user, checkPro } = useContext(AuthContext);

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [confirmTrue, setConfirmTrue] = useState(true);
  const [errText, setErrText] = useState(null);
  const [errText2, setErrText2] = useState(null);
  const [tab, setTab] = useState(1);
  const [BuyNow, setBuyNow] = useState(false);
  const [Package, setPackage] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  const [amount, setAmount] = useState();
  const [payCoin, setpayCoin] = useState("bitcoin");
  const { Price } = useContext(DataContext);
  const confirmPass = useRef();
  const pass = useRef();
  const oldPass = useRef();
  const { userLogin } = useContext(AuthContext);
  const handlePasswordChange = async () => {
    if (pass.current.value.length >= 6) {
      setConfirmTrue(true);
      if (confirmPass?.current?.value === pass?.current?.value) {
        setConfirmTrue(true);
        await axios
          .post(`${process.env.REACT_APP_API_URL}/api/changeOldPass`, {
            oldPass: oldPass?.current?.value || null,
            newPass: pass.current.value,
            email: user.email,
          })
          .then(async (res) => {
            await axios
              .post(`${process.env.REACT_APP_API_URL}/api/refreshProfile`, {
                email: user.email,
              })

              .then((res) => {
                toast.success("Password Change Successful");
                userLogin({
                  token: res.data.token,
                  name: res.data.name,
                  email: res.data.email,
                  pro: res.data.pro,
                  trialAvailble: res.data.trialAvailble,
                  passCheck: res.data.passCheck,
                });
                oldPass.current.value = "";
                pass.current.value = "";
                confirmPass.current.value = "";
              });
          })

          .catch((response) => {
            if (response.response.status === 401) {
              toast.error("Old Password is Incorrect");
            } else {
              toast.error("Something Went Wrong");
            }
          });
      } else {
        setConfirmTrue(false);
        setErrText2("Passwords do not match");
      }
    } else {
      setConfirmTrue(false);
      setErrText("Password Should be More then 6 letters");
    }
  };

  const data = [
    {
      name: "Custom Strategy Builder",
      Pro: true,
      basic: true,
      free: true,
      desc: "Build custom strategies, see payoff diagrams, analyse scenarios, and trade with a single click.",
    },
    {
      name: "Option Chain",
      Pro: true,
      basic: true,
      free: true,
      desc: null,
    },
    {
      name: "Analyse Trades & Positions",
      Pro: true,
      basic: true,
      free: true,
      desc: "Analyse your positions under various scenarios, visualize the payoffs, modify the strategies, perform adjustments, etc",
    },
    {
      name: "Analyse Positions",
      Pro: true,
      basic: true,
      free: false,
      desc: "Analyse your positions under various scenarios, visualize the payoffs, modify the strategies, perform adjustments, etc",
    },
    {
      name: "Virtual Trade",
      Pro: true,
      basic: true,
      free: false,
      desc: "New to trading? Learn and experiment without losing money",
    },
    {
      name: "Save Trades",
      Pro: true,
      basic: true,
      free: false,
      desc: null,
    },
    {
      name: "Lifetime Access",
      Pro: true,
      basic: false,
      free: false,
      desc: null,
    },
  ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${user.token}`,
  };

  const activateTrail = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/activatetrial`,
        {},
        {
          headers: headers,
        }
      )
      .then(async (res) => {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/api/refreshProfile`, {
            email: user.email,
          })
          .then((res) => {
            toast.success("Trail Activated Successfully");
            userLogin({
              token: res.data.token,
              name: res.data.name,
              email: res.data.email,
              pro: res.data.pro,
              trialAvailble: res.data.trialAvailble,
              passCheck: res.data.passCheck,
              pro_expiry: res.data.pro_expiry,
            });
          })
          .catch((res) => {
            toast.error("Something Went Wrong");
          });
      })
      .catch((res) => {
        toast.error("Something Went Wrong");
      });
  };

  const daysLeft = (pro_expiry) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(pro_expiry);
    const secondDate = new Date();
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    return diffDays;
  };

  return (
    <div>
      {tab === 1 && (
        <div className="bg-[#efefef]">
          <div className="pt-[100px] max-w-3xl  mx-auto ">
            <div className="flex flex-col">
              <div className="flex md:flex-row flex-col md:space-x-9 space-y-4 md:space-y-0">
                <div className="  flex-[0.4] py-2 px-3 bg-[#68cae3] rounded-md shadow-lg ">
                  <div className="flex items-center gap-4 ">
                    <Avatar
                      className="rounded-full !h-12 !w-12"
                      name={user?.name}
                    />
                    <p className="font-bold text-white text-md ">{user.name}</p>
                  </div>
                </div>
                <div className="bg-white flex-[0.7] flex items-center  rounded-md shadow-md">
                  <div className="px-4 border-r border-black/20">
                    {" "}
                    <img src={email} alt="" className="w-10 h-8" />
                  </div>
                  <div className="flex flex-col ">
                    <p className="uppercase font-semibold  text-gray-400 text-xs pl-2">
                      GOOGLE ID
                    </p>
                    <p className="pl-2 text-sm font-semibold ">{user?.email}</p>
                  </div>
                </div>
              </div>

              <div className="w-full bg-white mt-6 shadow-md  rounded-md">
                <div className="text-gray-600 pl-1.5  flex border-b  ">
                  <div
                    className={`flex flex-col border-r cursor-pointer ${tab === 1 && " scale-105 shadow-md"
                      }`}
                    onClick={() => setTab(1)}
                  >
                    <h1 className="uppercase  text-sm px-3 py-2 shadow-md rounded-md italic  ">
                      STRIKEBIT Subscription{" "}
                    </h1>
                    <div
                      className={`w-full ${tab === 1 && "bg-[#68cae3] h-0.5"
                        } transition-all duration-200 ease-in-out `}
                    />
                  </div>
                  <div
                    className={`flex flex-col border-r cursor-pointer ${tab === 2 && " scale-105 shadow-md"
                      }`}
                    onClick={() => setTab(2)}
                  >
                    <h1 className="uppercase ] text-sm px-3 py-2 shadow-md rounded-md italic  ">
                      Settings
                    </h1>
                    <div
                      className={`w-full ${tab === 2 && "bg-[#68cae3] h-0.5  "
                        } transition-all duration-200 ease-in-out  `}
                    />
                  </div>
                </div>
                <div className="p-4">
                  <p className="font-semibold text-md uppercase flex gap-2">
                    <img
                      src="/PNSB.png"
                      alt="STRIKEBET"
                      className="w-[100px] object-contain"
                    />
                    Subscriptions
                  </p>
                  <div className="mt-3 bg-[whitesmoke] p-3">
                    <div className="flex items-center space-x-5">
                      {" "}
                      <p className="bg-[#e8fee6] text-green-600 px-3 py-1 border border-green-500/10 rounded-md">
                        Active
                      </p>
                      <p className="font-semibold">
                        {user.pro && checkPro(user?.pro_expiry)
                          ? `Pro Plan - ${daysLeft(
                            user.pro_expiry
                          )} days left for Pro Expiry`
                          : "Free Plan"}
                      </p>
                      {user?.trialAvailble && (
                        <button
                          class="text-white gradientBgAnimated focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800  shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-3 py-1.5 text-center  transition"
                          onClick={activateTrail}
                        >
                          <span>Activate Free Trial</span>
                        </button>
                      )}
                    </div>
                    <p className="mt-2 text-gray-500 italic text-xs ">
                      Unlock ultimate packed features like Virtual Trade,
                      Strategy Builder, Open Interest Analysis, and much more
                      with <span className="text-[#7c92ce] font-extrabold">STRIKEBIT</span> Pro.
                    </p>
                  </div>
                  {BuyNow ? (
                    <div>
                      {paymentDone ? (
                        <div className="flex flex-col">
                          <div className="flex justify-center">
                            {" "}
                            <img
                              src={payment}
                              alt=""
                              className="w-36 h-36 py-1"
                            />
                          </div>
                          <p className="italic text-center text-gray-600">
                            Thank You for Payment
                          </p>
                          <p className="italic text-center text-gray-600">
                            Please send a message to the discord support group.{" "}
                            <a
                              href="https://discord.gg/wMhgyWPbJB"
                              className="text-black text-xs underline"
                            >
                              Click Here
                            </a>
                          </p>
                          <button
                            onClick={() => {
                              setPaymentDone(false);
                              setBuyNow(false);
                            }}
                            className="px-4 py-1.5 mt-2 bg-[#68cae3] shadow-md ring-gray-200 ring hover:scale-[102%] active:scale-95 text-white"
                          >
                            Done
                          </button>
                        </div>
                      ) : (
                        <div className="flex flex-col md:flex-row items-center relative ">
                          <div className="">
                            {payCoin !== "other" ? (
                              <>
                                {payCoin === "USDT" ? (
                                  <img
                                    className="w-[400px] h-[400]"
                                    src={`https://chart.googleapis.com/chart?cht=qr&chl=ethereum%3A${process.env.REACT_APP_ETH_ADDRESS}%3Famount%3D0%26message%3DTHORPAY%20${Package}%20Subscription&chs=180x180&choe=UTF-8&chld=L|2`}
                                    alt="subscription img"
                                  />
                                ) : (
                                  <img
                                    className="w-[400px] h-[400]"
                                    src={`https://chart.googleapis.com/chart?cht=qr&chl=${payCoin}%3A${process.env.REACT_APP_BTC_ADDRESS}%3Famount%3D${amount}%26message%3DTHORPAY%20${Package}%20Subscription&chs=180x180&choe=UTF-8&chld=L|2`}
                                    alt="subscription img"
                                  />
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className="flex-col w-full mt-6">
                            {payCoin !== "other" && (
                              <>
                                {payCoin === "USDT" ? (
                                  <p className="italic text-md text-gray-500">
                                    <span className="font-semibold text-black ">
                                      Address -{" "}
                                    </span>{" "}
                                    {process.env.REACT_APP_ETH_ADDRESS}
                                  </p>
                                ) : (
                                  <p className="italic text-md text-gray-500">
                                    <span className="font-semibold text-black ">
                                      Address -{" "}
                                    </span>{" "}
                                    {process.env.REACT_APP_BTC_ADDRESS}
                                  </p>
                                )}
                              </>
                            )}
                            <div>
                              <select
                                name=""
                                id=""
                                className="px-3 py-1.5 border-black/20 rounded-md bg-transparent my-2 shadow-md border"
                                onChange={(e) => {
                                  setpayCoin(e.target.value);
                                  if (e.target.value === "USDT") {
                                    setAmount(Math.round(Price * amount));
                                  }
                                  if (e.target.value === "bitcoin") {
                                    if (Package === "Pro") {
                                      setAmount((480 / Price).toFixed(5));
                                    } else {
                                      setAmount((2000 / Price).toFixed(5));
                                    }
                                  }
                                }}
                              >
                                <option
                                  value="bitcoin"
                                  defaultChecked
                                  defaultValue
                                >
                                  Bitcoin
                                </option>
                                <option value="USDT">USDT</option>
                                <option value="other">Other</option>
                              </select>
                            </div>
                            <p className="text-gray-500">
                              {" "}
                              <span className="font-semibold text-black ">
                                Amount -{" "}
                              </span>{" "}
                              {payCoin === "other" ? (
                                <>{Package === "Pro" ? 480 : 2000} $</>
                              ) : (
                                `${amount} ${payCoin}`
                              )}
                            </p>
                            <div>
                              {payCoin === "other" ? (
                                <h1 className="text-lg mt-2 mb-2 text-black italic font-semibold ">
                                  Contact Discord Support For Further Process
                                </h1>
                              ) : (
                                <h1 className="text-lg mt-2 mb-2 text-black italic font-semibold ">
                                  Instructions for Payment
                                </h1>
                              )}
                              {payCoin === "other" ? (
                                <ul className="text-gray-500 space-y-1 list-disc px-6 text-sm italic">
                                  <li>
                                    Goto to our discord channel find the support
                                    and create a ticket for further process
                                  </li>
                                  <li>
                                    In other payment option we process all kind
                                    of methods like UPI, Card, NetBanking etc
                                  </li>
                                  <li>
                                    External Charges might be charged for the
                                    process
                                  </li>
                                </ul>
                              ) : (
                                <>
                                  {payCoin === "bitcoin" && (
                                    <ul className="text-gray-500 space-y-1 list-disc px-6 text-sm italic">
                                      <li>
                                        Check the amount properly before
                                        sending. Insert Amount manually if not
                                        captured automatic in wallet
                                      </li>
                                      <li>Send Only Bitcoin on this address</li>
                                      <li>
                                        You can send funds from Exchange as
                                        well.
                                      </li>
                                    </ul>
                                  )}
                                  {payCoin === "USDT" && (
                                    <ul className="text-gray-500 space-y-1 list-disc px-6 text-sm italic">
                                      <li>
                                        Check the amount properly before
                                        sending. Insert Amount manually.
                                      </li>
                                      <li>
                                        Send Only USDT ERC20 on Ethereum Network
                                        on this address
                                      </li>
                                      <li>
                                        You can send funds from Exchange as
                                        well.
                                      </li>
                                    </ul>
                                  )}
                                </>
                              )}

                              <div className="mt-4 flex w-full justify-end space-x-3">
                                <button
                                  className="py-1.5 px-3 bg-red-500 text-white font-semibold shadow-md rounded-md   "
                                  onClick={() => setBuyNow(false)}
                                >
                                  Cancal
                                </button>
                                <button
                                  className="py-1.5 px-3 bg-green-500 text-white font-semibold shadow-md rounded-md   "
                                  onClick={() => setPaymentDone(true)}
                                >
                                  Payment Done
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="mt-5 flex-col md:flex-row flex p-2 md:space-x-[1px] space-y-4 md:space-y-0">
                      <div className="border border-black/20 basis-1/3  p-2">
                        <div className="flex py-3 border-b justify-between items-center">
                          <div className="flex space-x-2">
                            {" "}
                            <h1 className="font-semibold italic text-black ">
                              Lifetime
                            </h1>
                            <h1 className="animate-bounce rounded-md bg-green-500 text-white px-1 text-sm py-1 ">
                              Best Value
                            </h1>
                          </div>
                          <h1 className="text-sm text-gray-500 italic">
                            LifeTime
                          </h1>
                        </div>
                        <div className="relative h-[200px] border-b pb-3 flex flex-col items-center justify-center">
                          <img
                            src={lifetime}
                            className="rounded-full  w-32 h-28 py-2  px-4 "
                            alt=""
                          />
                          <p className="text-gray-500 italic text-sm py-2">
                            One Time Payment - 2000$
                          </p>
                          <button
                            class="custom-btn btn-12 text-sm"
                            onClick={() => {
                              setPackage("Lifetime");
                              setAmount((2000 / Price).toFixed(5));
                              setBuyNow(true);
                            }}
                          >
                            <span>Buy Now!</span>
                            <span>Choose LifeTime</span>
                          </button>
                        </div>
                        <div className="flex flex-col items-center justify-center mb-4">
                          <p className=" text-gray-500 my-2 italic text-xs">
                            Highlights
                          </p>

                          <p className="text-black text-sm">All In Pro</p>
                          <p className="text-black text-sm">Life Time Access</p>
                          <p className="text-black text-sm">
                            All Upcoming Features
                          </p>
                          <s className="text-black text-sm">5000 USD </s>
                        </div>
                      </div>
                      <div className="border border-black/20 basis-1/3  p-2">
                        <div className="flex py-3 border-b justify-between items-center">
                          <div className="flex space-x-3">
                            {" "}
                            <h1 className="font-semibold italic text-black ">
                              Pro
                            </h1>
                          </div>
                          <h1 className="text-sm text-gray-500 italic">
                            1.3$ / Day
                          </h1>
                        </div>
                        <div className="relative h-[200px] border-b pb-3 flex flex-col items-center justify-center">
                          <img
                            src={pro}
                            className="rounded-full  w-32 h-32  px-4 "
                            alt=""
                          />
                          <p className="text-gray-500 italic text-sm py-2">
                            40$ / month
                          </p>
                          <button
                            class="custom-btn btn-12 text-sm"
                            onClick={() => {
                              setPackage("Pro");
                              setAmount((480 / Price).toFixed(5));
                              setBuyNow(true);
                            }}
                          >
                            <span>Buy Now!</span>
                            <span>Choose Pro</span>
                          </button>
                        </div>
                        <div className="flex flex-col items-center justify-center mb-4">
                          <p className=" text-gray-500 my-2 italic text-xs">
                            Highlights
                          </p>

                          <p className="text-black text-sm">All In Free</p>
                          <p className="text-black text-sm">
                            Unlimited Trades Save
                          </p>
                          <p className="text-black text-sm">
                            All Upcoming Features
                          </p>
                          <s className="text-black text-sm">50 USD Montly</s>
                        </div>
                      </div>
                      <div className="border border-black/20 basis-1/3  p-2">
                        <div className="flex py-3 border-b justify-between items-center">
                          <div className="flex space-x-3">
                            {" "}
                            <h1 className="font-semibold italic text-black ">
                              Free
                            </h1>
                          </div>
                        </div>
                        <div className="relative h-[200px] border-b flex flex-col items-center justify-center">
                          <img
                            src={basic}
                            className="rounded-md mt-3 w-24 h-24 py-4 px-4 "
                            alt=""
                          />
                        </div>
                        <div className="flex flex-col items-center justify-center mb-4">
                          <p className=" text-gray-500 my-2 italic text-xs">
                            Highlights
                          </p>

                          <p className="text-black text-sm">Strategy Builder</p>
                          <p className="text-black text-sm">Analysis Trades</p>
                          <p className="text-black text-sm">Option Chain</p>
                          <p className="text-black text-sm">
                            View Payoff Chart
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="bg-white md:p-4 mb-6 p-1 mt-6 text-xs md:text-md shadow-md rounded-md border  ">
                <div>
                  <h1 className="text-black text-lg font-semibold text-center ">
                    Compare Plans
                  </h1>
                  <div className="flex flex-col p-2">
                    <div className="flex bg-gray-100 ">
                      <div className=" basis-2/5 flex items-center text-gray-600">
                        <h1 className="ml-4 ">Trade Options</h1>
                      </div>
                      <div className=" basis-1/5 flex justify-center font-semibold py-2 italic">
                        <h1>LifeTime</h1>
                      </div>
                      <div className=" basis-1/5 flex justify-center font-semibold py-2 italic">
                        <h1>Pro</h1>
                      </div>
                      <div className=" basis-1/5 flex justify-center font-semibold py-2 italic">
                        <h1>Free</h1>
                      </div>
                    </div>
                    {data.map((item, i) => (
                      <div key={i}>
                        {" "}
                        {item.notTableItem ? (
                          <div className=" bg-gray-200">
                            <div className="  basis-2/5 flex items-center text-gray-400 ">
                              <div className="ml-4 inline-flex gap-1 relative  items-center">
                                <p> {item.name} </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex ">
                            <div className="border  basis-2/5 flex items-center text-gray-600">
                              <div className="ml-4 inline-flex gap-1 relative  items-center">
                                <p> {item.name} </p>
                                <p className="group hidden md:inline-block">
                                  {" "}
                                  {item.desc ? (
                                    <InformationCircleIcon className="w- h-4 mt-2 text-gray-500" />
                                  ) : (
                                    <></>
                                  )}
                                  {item.desc && (
                                    <p
                                      className={`bg-black z-[99] text-xs text-white px-4 hidden py-2 group-hover:inline-block rounded-md w-[300px] transition-all duration-200 ease-in-out absolute left-20`}
                                    >
                                      {item.desc}
                                    </p>
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="border-[0.3px] basis-1/5 flex justify-center  py-2 italic">
                              {item?.Pro ? (
                                <CheckCircleIcon className="text-green-500 w-8 h-8" />
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="border-[0.3px] basis-1/5 flex justify-center font-semibold py-2 italic">
                              {item?.basic ? (
                                <CheckCircleIcon className="text-green-500 w-8 h-8" />
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="border-[0.3px] basis-1/5 flex justify-center font-semibold py-2 italic">
                              {item?.free ? (
                                <CheckCircleIcon className="text-green-500 w-8 h-8" />
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {tab === 2 && (
        <div className="bg-[#efefef] h-screen">
          <div className="pt-[100px] max-w-3xl  mx-auto ">
            <div className="flex flex-col">
              <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-8">
                <div className="  flex-[0.4] py-2 px-3 bg-[#68cae3] rounded-md shadow-lg ">
                  <div className="flex items-center gap-4 ">
                    <Avatar
                      className="rounded-full !h-12 !w-12"
                      name={user?.name}
                    />
                    <p className="font-bold text-white text-md ">{user.name}</p>
                  </div>
                </div>
                <div className="bg-white flex-[0.7] py-4 flex items-center rounded-md shadow-md">
                  <div className="px-2 border-r border-black/20">
                    {" "}
                    <img src={email} alt="" className="w-8 h-6" />
                  </div>
                  <div className="flex flex-col">
                    <p className="uppercase font-semibold text-gray-400 text-xs pl-2">
                      GOOGLE ID
                    </p>
                    <p className="pl-2 text-sm font-semibold ">{user?.email}</p>
                  </div>
                </div>
              </div>

              <div className="w-full bg-white mt-6 shadow-md rounded-md h-full">
                <div className="text-gray-600  flex border-b  ">
                  <div
                    className={`flex flex-col border-r cursor-pointer ${tab === 1 && " scale-105 shadow-md"
                      }`}
                    onClick={() => setTab(1)}
                  >
                    <h1 className="uppercase  text-sm px-3 py-2 shadow-md rounded-md italic  ">
                      STRIKEBIT Subscription{" "}
                    </h1>
                    <div
                      className={`w-full ${tab === 1 && "bg-[#68cae3] h-0.5"
                        } transition-all duration-200 ease-in-out `}
                    />
                  </div>
                  <div
                    className={`flex flex-col border-r cursor-pointer ${tab === 2 && " scale-105 shadow-md"
                      }`}
                    onClick={() => setTab(2)}
                  >
                    <h1 className="uppercase  text-sm px-3 py-2 shadow-md rounded-md italic  ">
                      Settings
                    </h1>
                    <div
                      className={`w-full ${tab === 2 && "bg-[#68cae3] h-0.5  "
                        } transition-all duration-200 ease-in-out  `}
                    />
                  </div>
                </div>
                {user.passCheck ? (
                  <div className="p-4">
                    <div className="">
                      <h1 className="font-semibold inline-flex flex-col   italic text-black text-md ">
                        Change Password
                      </h1>
                      <p className="text-xs text-gray-500 italic">
                        *Change Your Account Password*{" "}
                      </p>
                    </div>
                    <div className="flex flex-col mt-3 space-y-4 ">
                      <div className=" w-full flex justify-center relative">
                        <input
                          ref={oldPass}
                          type="password"
                          placeholder="Enter Your Old Password"
                          className={`px-4 py-1.5 border border-black/20 placeholder:text-sm text-semibold  text-center  outline-none rounded-md shadow-md `}
                        />
                      </div>
                      <div className="flex w-full md:flex-row flex-col md:space-y-0  space-y-4 md:justify-around">
                        <div className=" mx-auto ">
                          <div className="relative">
                            <input
                              ref={pass}
                              type={`${showPassword1 ? "text" : "password"}`}
                              placeholder="Enter New Password"
                              className={`px-4 py-1.5 border
                      border-black/20 placeholder:text-sm text-semibold  text-center  outline-none rounded-md shadow-md `}
                            />

                            {showPassword1 ? (
                              <EyeSlashIcon
                                className="absolute w-5 h-5 cursor-pointer  right-5 top-[25%]"
                                onClick={() => setShowPassword1(!showPassword1)}
                              />
                            ) : (
                              <EyeIcon
                                className="absolute w-5 h-5 cursor-pointer  right-5 top-[25%]"
                                onClick={() => setShowPassword1(!showPassword1)}
                              />
                            )}
                          </div>
                          {!confirmTrue && (
                            <p className="text-center text-sm italic text-red-500">
                              {errText}
                            </p>
                          )}
                        </div>
                        <div className=" mx-auto ">
                          <div className="relative">
                            <input
                              ref={confirmPass}
                              type={`${showPassword2 ? "text" : "password"}`}
                              placeholder="Enter New Password"
                              className={`px-4 py-1.5  placeholder:text-sm text-semibold border text-center  outline-none rounded-md shadow-md ${confirmTrue
                                ? "border-black/20"
                                : "border-red-500"
                                }`}
                            />

                            {showPassword2 ? (
                              <EyeSlashIcon
                                className="absolute w-5 h-5 cursor-pointer  right-4 top-[25%]"
                                onClick={() => setShowPassword2(!showPassword2)}
                              />
                            ) : (
                              <EyeIcon
                                className="absolute w-5 h-5 cursor-pointer  right-4 top-[25%]"
                                onClick={() => setShowPassword2(!showPassword2)}
                              />
                            )}
                          </div>
                          {!confirmTrue && (
                            <p className="text-center text-sm italic text-red-500">
                              {errText2}
                            </p>
                          )}
                        </div>
                      </div>
                      <button
                        className="bg-[#68cae3] py-1.5 text-white font-semibold italic active:scale-95"
                        onClick={handlePasswordChange}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="p-4">
                    <div className="">
                      <h1 className="font-semibold inline-flex flex-col   italic text-black text-md ">
                        Add Password
                      </h1>
                      <p className="text-xs text-gray-500 italic">
                        *Add Your Account Password*{" "}
                      </p>
                    </div>
                    <div className="flex flex-col mt-3 space-y-4 ">
                      <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 w-full justify-around">
                        <div className=" mx-auto ">
                          <div className="relative">
                            <input
                              ref={pass}
                              type={`${showPassword1 ? "text" : "password"}`}
                              placeholder="Enter Your New Password"
                              className={`px-4 py-1.5 border
                      border-black/20 placeholder:text-sm text-semibold  text-center  outline-none rounded-md shadow-md `}
                            />

                            {showPassword1 ? (
                              <EyeSlashIcon
                                className="absolute w-5 h-5 cursor-pointer  right-5 top-[25%]"
                                onClick={() => setShowPassword1(!showPassword1)}
                              />
                            ) : (
                              <EyeIcon
                                className="absolute w-5 h-5 cursor-pointer  right-5 top-[25%]"
                                onClick={() => setShowPassword1(!showPassword1)}
                              />
                            )}
                          </div>
                          {!confirmTrue && (
                            <p className="text-center text-sm italic text-red-500">
                              {errText}
                            </p>
                          )}
                        </div>
                        <div className=" mx-auto ">
                          <div className="relative">
                            <input
                              ref={confirmPass}
                              type={`${showPassword2 ? "text" : "password"}`}
                              placeholder="Enter Your New Password"
                              className={`px-4 py-1.5  placeholder:text-sm text-semibold border text-center  outline-none rounded-md shadow-md ${confirmTrue
                                ? "border-black/20"
                                : "border-red-500"
                                }`}
                            />

                            {showPassword2 ? (
                              <EyeSlashIcon
                                className="absolute w-5 h-5 cursor-pointer  right-2 top-[25%]"
                                onClick={() => setShowPassword2(!showPassword2)}
                              />
                            ) : (
                              <EyeIcon
                                className="absolute w-5 h-5 cursor-pointer  right-2 top-[25%]"
                                onClick={() => setShowPassword2(!showPassword2)}
                              />
                            )}
                          </div>
                          {!confirmTrue && (
                            <p className="text-center text-sm italic text-red-500">
                              {errText2}
                            </p>
                          )}
                        </div>
                      </div>
                      <button
                        className="bg-[#68cae3] py-1.5 text-white font-semibold italic active:scale-95"
                        onClick={handlePasswordChange}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <p className="py-4 text-sm text-center italic text-gray-500">
                *More Settings will be Available Soon*
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfilePage;
