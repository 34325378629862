import React from "react";
import { FiSettings } from "react-icons/fi";
function Setting() {
  return (
    <div className="flex h-12 p-4 border-[1px] items-center text-center shadow-md flex-wrap content-center bg-white  border-black/30 rounded-lg">
      <div className="flex items-center gap-2">
        {" "}
        <FiSettings />
        Setting
      </div>
    </div>
  );
}

export default Setting;
