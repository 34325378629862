import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import GreeksTable from "./GreeksTable";

function Summary(props) {
  return (
    <div>
      <div className="flex bg-white md:flex-row flex-col  p-4 md:space-x-20 space-y-8 md:space-y-0 rounded-lg border-[1px]  mb-4">
        <div className="flex flex-col basis-1/2 justify-center ">
          <h1 className="text-center font-semibold">Summary</h1>
          <div className="flex justify-between border-b-[1px] py-2">
            <h1 className="text-sm text-gray-700">Max Profit</h1>
            <p className="text-sm font-semibold text-green-700">
              {" "}
              {props.data.maxProfit}
            </p>
          </div>
          <div className="flex justify-between border-b-[1px] py-2 relative group  transition-all duration-300 ease-in-out">
            <h1 className="text-sm text-gray-700 flex gap-1 items-center">
              Max Loss span <AiOutlineQuestionCircle className="group" />
              <p className="position absolute text-xs w-[250px] top-8 bg-black px-5 py-2 text-white transition-all ease-in-out hidden group-active:block ">
                You cannot lose more than the maximum loss. Even if the trade
                goes completely wrong, the market goes up or crashes. br
                However, in the case of calendar spreads, the max loss can be
                more than this number, as some option legs are open when the
                earliest expiry leg expires.
              </p>
            </h1>
            <p className="text-sm font-semibold text-red-700">
              {" "}
              {props.data.maxLoss}
            </p>
          </div>
          <div className="flex justify-between border-b-[1px] py-2 relative group  transition-all duration-300 ease-in-out">
            <h1 className="text-sm text-gray-700 flex gap-1 items-center">
              Risk / Reward
            </h1>
            <p
              className={`text-sm font-semibold ${(props.data.riskReward <= 30 && "text-red-500") ||
                (props.data.riskReward >= 50 && "text-green-500")
                }`}
            >
              {" "}
              {isNaN(props.data.riskReward) ? 0 : props.data.riskReward}
            </p>
          </div>
          <div className="flex justify-between border-b-[1px] py-2 relative group  transition-all duration-300 ease-in-out">
            <h1 className="text-sm text-gray-700 flex gap-1 items-center">
              Breakeven <AiOutlineQuestionCircle className="group" />
            </h1>
            <p className={`text-sm font-semibold  `}>
              {props.data.breakeven.map(
                (item, index) => index === 0 && `${item}...`
              )}
            </p>
            <p
              className={`text-sm font-semibold absolute w-[250px] top-8 bg-black text-white right-1 p-2  hidden group-hover:block `}
            >
              {props.data.breakeven.map((item, index) => `${item} `)}
            </p>
          </div>
        </div>
        <div className="flex flex-col md:basis-1/2 md:justify-center ">
          <div>
            {" "}
            <GreeksTable props={props} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
