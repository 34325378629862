import React, { createContext, useState } from "react";

const StrategyContext = createContext();

export function StrategyProvider({ children }) {
  const [StrategyFor, setStrategy] = useState([]);
  const updateStrategy = (scriptList) => {
    setStrategy(scriptList);
  };

  return (
    <StrategyContext.Provider value={{ StrategyFor, updateStrategy }}>
      {children}
    </StrategyContext.Provider>
  );
}

export { StrategyContext };
