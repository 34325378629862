import axios from "axios";

const OptionPayoff = function single(options, meta) {
  // console.log(options);
  return {
    options: options,
    meta: meta,
    payoff: multiOptionCalc(options, meta),
  };
};

function multiOptionCalc(options, meta) {
  var payoff = [];
  var individualPayoffs = [];

  for (var i = 0; i < options.length; i += 1)
    individualPayoffs.push(singleOptionCalc(options[i], meta));

  for (var io = 0; io < meta.priceRange.length; io = io + 1) {
    var pay = 0;

    for (var ip = 0; ip < options.length; ip = ip + 1) {
      pay += individualPayoffs[ip][io];
    }

    payoff.push(pay);
  }

  return payoff;
}

function singleOptionCalc(option, meta) {
  var premium = option.Premium;

  var side = option.Direction.toUpperCase() === "BUY" ? 1 : -1;
  var payoff = [];

  if (option.Type.toUpperCase() === "CALL")
    for (var ic in meta.priceRange)
      payoff.push(
        (Math.max(meta.priceRange[ic] - option.StrikePrice, 0) - premium) *
        side *
        option.lots
      );
  else
    for (var ip in meta.priceRange)
      payoff.push(
        (Math.max(option.StrikePrice - meta.priceRange[ip], 0) - premium) *
        side *
        option.lots
      );
  return payoff;
}

const priceRange = function (currentPrice, numberOfPrices, startegyFor) {
  const priceRange = [];
  let netPremium = 0;
  let callStrikes = [];
  let putStrikes = [];
  startegyFor.map((script) =>
    script.Direction === "sell"
      ? (netPremium += Number(script.Premium))
      : (netPremium -= Number(script.Premium))
  );
  startegyFor.forEach((script) => {
    if (script.Type === "CALL" && script.Direction === "sell") {
      callStrikes.push(script.StrikePrice + netPremium);
    } else if (script.Type === "PUT" && script.Direction === "sell") {
      putStrikes.push(script.StrikePrice - netPremium);
    } else if (script.Type === "CALL" && script.Direction === "buy") {
      callStrikes.push(script.StrikePrice - netPremium);
    } else if (script.Type === "PUT" && script.Direction === "buy") {
      putStrikes.push(script.StrikePrice + netPremium);
    }
  });
  for (let i = numberOfPrices; i > 0; i--) {
    priceRange.push(currentPrice - i * 500);
  }
  priceRange.push(...callStrikes);
  priceRange.push(...putStrikes);
  for (let i = 0; i <= numberOfPrices; i++) {
    priceRange.push(currentPrice + i * 500);
  }
  priceRange.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });
  return priceRange;
};

const summaryData = function (chartData) {
  // console.log(chartData);
  const firstPriceDifference = chartData[1].price - chartData[0].price;
  const firstPnlDifference = chartData[1].pnl - chartData[0].pnl;
  const lastPriceDifference =
    chartData[chartData.length - 1].price -
    chartData[chartData.length - 2].price;
  const lastPnlDifference =
    chartData[chartData.length - 1].pnl - chartData[chartData.length - 2].pnl;
  const maxProfit =
    (firstPriceDifference <= Math.abs(firstPnlDifference) &&
      firstPnlDifference < 0) ||
      (lastPriceDifference <= Math.abs(lastPnlDifference) &&
        lastPnlDifference > 0)
      ? "Unlimited"
      : chartData.reduce((a, b) => Math.max(a, b.pnl), 0).toFixed(2);
  const maxLoss =
    (firstPriceDifference <= Math.abs(firstPnlDifference) &&
      firstPnlDifference > 0) ||
      (lastPriceDifference <= Math.abs(lastPnlDifference) &&
        lastPnlDifference < 0)
      ? "Unlimited"
      : chartData.reduce((a, b) => Math.min(a, b.pnl), 0).toFixed(2);
  const data = chartData.filter((item) => Math.round(item.pnl) === 0);
  const breakeven = data ? data.map((item) => item.price) : NaN;
  const riskReward =
    maxLoss === "Unlimited" ? "NaN" : (maxProfit / maxLoss).toFixed(2);
  return {
    maxProfit,
    maxLoss,
    breakeven,
    riskReward,
  };
};

const strategyScripts = async function (strategy, price, asset, expiry) {
  // Send Contract with different expiries
  const scripts = [];
  const strikesData = [];
  let scriptData = [];
  let closestStrike = Math.round(price / 500) * 500;

  strategy.forEach((item) => {
    strikesData.push({
      StrikePrice: closestStrike + item.StrikePrice * 500,
      expiry: expiry,
    });
  });
  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/fetchcontracts`, {
      contracts: strikesData,
      asset: asset,
    })
    .then((response) => {
      strategy.forEach((item, index) => {
        console.log(item);
        scriptData = [];
        scriptData = {
          id: index,
          Direction: item.Direction,
          StrikePrice: strikesData[index].StrikePrice,
          Expiry: strikesData[index].expiry,
          Type: item.Type,
          lots: item.lots,
          check: true,
          Premium:
            item.Type === "CALL"
              ? response.data.contractsData.filter(
                (Data) => Data.StrikePrice === strikesData[index].StrikePrice
              )[0].callPremium
              : response.data.contractsData.filter(
                (Data) => Data.StrikePrice === strikesData[index].StrikePrice
              )[0].putPremium,
          Delta:
            item.Type === "CALL"
              ? response.data.contractsData.filter(
                (Data) => Data.StrikePrice === strikesData[index].StrikePrice
              )[0].callDelta
              : response.data.contractsData.filter(
                (Data) => Data.StrikePrice === strikesData[index].StrikePrice
              )[0].putDelta,
          Gamma:
            item.Type === "CALL"
              ? response.data.contractsData.filter(
                (Data) => Data.StrikePrice === strikesData[index].StrikePrice
              )[0].callGamma
              : response.data.contractsData.filter(
                (Data) => Data.StrikePrice === strikesData[index].StrikePrice
              )[0].putGamma,
          Rho:
            item.Type === "CALL"
              ? response.data.contractsData.filter(
                (Data) => Data.StrikePrice === strikesData[index].StrikePrice
              )[0].callRho
              : response.data.contractsData.filter(
                (Data) => Data.StrikePrice === strikesData[index].StrikePrice
              )[0].putRho,
          Theta:
            item.Type === "CALL"
              ? response.data.contractsData.filter(
                (Data) => Data.StrikePrice === strikesData[index].StrikePrice
              )[0].callTheta
              : response.data.contractsData.filter(
                (Data) => Data.StrikePrice === strikesData[index].StrikePrice
              )[0].putTheta,
          Vega:
            item.Type === "CALL"
              ? response.data.contractsData.filter(
                (Data) => Data.StrikePrice === strikesData[index].StrikePrice
              )[0].callVega
              : response.data.contractsData.filter(
                (Data) => Data.StrikePrice === strikesData[index].StrikePrice
              )[0].putVega,
        };
        scripts.push(scriptData);
      });
    });
  return scripts;
};

function findGCD(arr) {
  function hcf(a, b) {
    if (a === 0) return b;
    return hcf(b % a, a);
  }
  let result = arr[0];
  for (let i = 1; i < arr.length; i++) {
    result = hcf(arr[i], result);

    if (result === 1) {
      return 1;
    }
  }
  return result;
}

const fetchExpiries = async function () {
  let expiries;
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/fetchexpiries`)
    .then((response) => {
      expiries = response.data.expiries;
    });
  return expiries;
};

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function lotsDropdown(quantity) {
  const lotsDropdown = [];
  for (let i = 1; i <= 150; i++) {
    if (quantity !== i) {
      lotsDropdown.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
  }
  return lotsDropdown;
}

export {
  OptionPayoff,
  priceRange,
  summaryData,
  strategyScripts,
  findGCD,
  fetchExpiries,
  monthNames,
  lotsDropdown,
};
