import React from "react";
import AssetSelect from "./SelectorPaneComponents/AssetSelect";
import Setting from "./SelectorPaneComponents/Setting";
import "./SelectorPane.css";
import LoadedStrategy from "./SelectorPaneComponents/LoadedStrategy";
import EmptyStrategy from "./SelectorPaneComponents/EmptyStrategy";
import StrikeSelection from "./StrikeSelection";
import { StrategyContext } from "../Contexts/StrategyContext";
import { useContext, useEffect, useState } from "react";
import { fetchExpiries, monthNames, strategyScripts } from "../CalculationFunctions";
import SelectStrategy from "./SelectorPaneComponents/SelectStrategy";
import axios from "axios";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { Directions } from "@mui/icons-material";
import { DataContext } from "../Contexts/DataContext";
import savedStrategy from "../Data/readyMadeStrategies.json";


function SelectorPane() {
  const [isStrategy, setStrategy] = useState(null);
  const [expiries, setExpiries] = useState([]);
  const [currentExpiry, setCurrentExpiry] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const { StrategyFor, updateStrategy } = useContext(StrategyContext);
  const [userSavedStrategys, setUserSavedStrategys] = useState([]);
  const [selectUserStrategy, setSelectUserStrategy] = useState(null);
  const { Price } = useContext(DataContext);
  const [defaultStrategy, setDefaultStrategy] = useState(null);

  // useEffect(() => {
  //   if (Price && currentExpiry) {
  //     strategyScripts(
  //       savedStrategy[0].Scripts,
  //       Price,
  //       "BTCUSD",
  //       currentExpiry
  //     ).then((result) => {
  //       setDefaultStrategy(result);
  //     });
  //   }
  // }, [Price, currentExpiry]);

  const fetchSavedStrategy = async (user) => {
    const headers = {
      Authorization: `Bearer ${user.token}`,
    };
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/getposition`, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.position)
          setUserSavedStrategys(response.data.position.PositionsInstances);
      })
      .catch((error) => {
        console.log(error); // Handle Signup Error
      });
  };

  const deleteSavedStrategy = async (user, positionId) => {
    const headers = {
      Authorization: `Bearer ${user.token}`,
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/deletePosition`,
        {
          positionId: positionId,
        },
        {
          headers: headers,
        }
      )
      .then(() => {
        setUserSavedStrategys(
          userSavedStrategys.filter(
            (userStrategy) => userStrategy.id !== positionId
          )
        );
        if (
          selectUserStrategy !== null &&
          selectUserStrategy.id === positionId
        ) {
          setSelectUserStrategy(null);
          updateStrategy([]);
          setStrategy(null);
        }
      })
      .catch((error) => {
        console.log(error); // Handle Signup Error
      });
  };

  const updateSavedStrategy = async (user, positionId, strategyName) => {
    const headers = {
      Authorization: `Bearer ${user.token}`,
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/updatePosition`,
        {
          strategyName: strategyName,
          positionId: positionId,
        },
        {
          headers: headers,
        }
      )
      .then(() => {
        let copySavedStrategys = [...userSavedStrategys];
        copySavedStrategys.find(
          (userStrategy) => userStrategy.id === positionId
        ).strategy = strategyName;
        setUserSavedStrategys(copySavedStrategys);
      })
      .catch((error) => {
        console.log(error); // Handle Signup Error
      });
  };


  const selectSavedStrategy = async (savedStrategyId) => {
    const selectSavedStrategy = userSavedStrategys.filter(
      (savedStrategy) => savedStrategy.id === savedStrategyId
    )[0];
    setSelectUserStrategy(selectSavedStrategy);
    const contracts = [];
    selectSavedStrategy.ScriptsInstances.map((item) =>
      contracts.push({ StrikePrice: item.strike, expiry: item.expiry })
    );
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/fetchcontracts`, {
        contracts: contracts,
        asset: "BTCUSD",
      })
      .then((response) => {
        const loadedUserStrategy = [];
        selectSavedStrategy.ScriptsInstances.forEach((item) => {
          const currentPrice =
            item.type === "CALL"
              ? response.data.contractsData.filter(
                (contract) =>
                  contract.expiry === item.expiry &&
                  contract.StrikePrice === item.strike
              )[0].callPremium
              : response.data.contractsData.filter(
                (contract) =>
                  contract.expiry === item.expiry &&
                  contract.StrikePrice === item.strike
              )[0].putPremium;
          const Delta =
            item.Type === "CALL"
              ? response.data.contractsData.filter(
                (Data) =>
                  Data.expiry === item.expiry &&
                  Data.StrikePrice === item.strike
              )[0].callDelta
              : response.data.contractsData.filter(
                (Data) =>
                  Data.expiry === item.expiry &&
                  Data.StrikePrice === item.strike
              )[0].putDelta;
          const Gamma =
            item.Type === "CALL"
              ? response.data.contractsData.filter(
                (Data) =>
                  Data.expiry === item.expiry &&
                  Data.StrikePrice === item.strike
              )[0].callGamma
              : response.data.contractsData.filter(
                (Data) =>
                  Data.expiry === item.expiry &&
                  Data.StrikePrice === item.strike
              )[0].putGamma;
          const Rho =
            item.Type === "CALL"
              ? response.data.contractsData.filter(
                (Data) =>
                  Data.expiry === item.expiry &&
                  Data.StrikePrice === item.strike
              )[0].callRho
              : response.data.contractsData.filter(
                (Data) =>
                  Data.expiry === item.expiry &&
                  Data.StrikePrice === item.strike
              )[0].putRho;
          const Theta =
            item.Type === "CALL"
              ? response.data.contractsData.filter(
                (Data) =>
                  Data.expiry === item.expiry &&
                  Data.StrikePrice === item.strike
              )[0].callTheta
              : response.data.contractsData.filter(
                (Data) =>
                  Data.expiry === item.expiry &&
                  Data.StrikePrice === item.strike
              )[0].putTheta;
          const Vega =
            item.Type === "CALL"
              ? response.data.contractsData.filter(
                (Data) =>
                  Data.expiry === item.expiry &&
                  Data.StrikePrice === item.strike
              )[0].callVega
              : response.data.contractsData.filter(
                (Data) =>
                  Data.expiry === item.expiry &&
                  Data.StrikePrice === item.strike
              )[0].putVega;
          loadedUserStrategy.push({
            Direction: item.direction,
            Expiry: item.expiry,
            Premium: item.entry,
            StrikePrice: item.strike,
            Type: item.type,
            check: true,
            id: item.id,
            lots: item.lots,
            PositionsInstanceId: item.PositionsInstanceId,
            exit: item.exit,
            currentPrice: currentPrice,
            Theta: Theta,
            Delta: Delta,
            Gamma: Gamma,
            Vega: Vega,
            Rho: Rho,
            unrealized:
              item.exit !== null
                ? 0
                : (item.direction === "sell" ? -1 : 1) *
                (
                  Math.round((currentPrice - item.entry) * item.lots * 100) /
                  100
                ).toFixed(2),
            booked:
              item.exit === null
                ? 0
                : (
                  Math.round((item.entry - item.exit) * item.lots * 100) / 100
                ).toFixed(2),
          });
        });
        updateStrategy(loadedUserStrategy);
      })
      .catch((error) => {
        console.log(error); // Handle Signup Error
      });
  };

  useEffect(() => {
    fetchExpiries().then((result) => {
      setExpiries(result);
      setCurrentExpiry(result[0]);
    });
  }, []);

  useEffect(() => {
    if (StrategyFor.length > 0) {
      setStrategy(StrategyFor);
    }
    else {
      setStrategy([{
        Delta: 0.451,
        Direction: "buy",
        Expiry: "2024-08-24T08:00:00.000Z",
        Gamma: 0.0007,
        Premium: 193.22,
        Rho: 0.085,
        StrikePrice: 61000,
        Theta: -995.011,
        Type: "CALL",
        Vega: 4.255,
        check: true,
        id: 0,
        lots: 1
      }]);
    }
  }, []);

  const clearStrategy = () => {
    updateStrategy([]);
    setStrategy(null);
    setSelectUserStrategy(null);
  };

  const updateScriptLots = (id, lot) => {
    let copyStrategy = [...StrategyFor];
    copyStrategy[id].lots = Number(lot);
    updateStrategy(copyStrategy);
  };

  return (
    <div className="flex flex-col  p-2 pt-2  ">
      {isVisible === true ? (
        <StrikeSelection
          asset="BTCUSD"
          currentExpiry={currentExpiry}
          isVisible={isVisible}
          setVisible={setVisible}
          expiries={expiries}
          setCurrentExpiry={setCurrentExpiry}
          monthNames={monthNames}
          clearStrategy={clearStrategy}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between gap-4 md:w-[500px]">
        <div className="md:w-full">
          <AssetSelect />
        </div>
        <div className="md:basis-1/5 hidden">
          <Setting />
        </div>
      </div>
      <div className="flex">
        <div className="flex max-h-96 w-full overflow-y-scroll scrollbar-thin scrollbar-thumb-rounded-md min-h-[]  scrollbar-thumb-gray-300 scrollbar-track-gray-100 overflow-x-hidden border-[1px] border-black/30 !pb-3 pt-2  bg-white mt-6">
          {isStrategy === null ? (
            <EmptyStrategy setVisible={setVisible} />
          ) : (
            <LoadedStrategy
              setVisible={setVisible}
              strategy={isStrategy}
              setStrategy={setStrategy}
              clearStrategy={clearStrategy}
              updateScriptLots={updateScriptLots}
              fetchSavedStrategy={fetchSavedStrategy}
              selectUserStrategy={selectUserStrategy}
              setSelectUserStrategy={setSelectUserStrategy}
            />
          )}
        </div>
      </div>
      <div className="flex-wrap  p-4 w-full overflow-auto h-full border-[1px] border-black/30 my-6 bg-white md:h-96 md:w-[500px] ">
        <SelectStrategy
          currentExpiry={currentExpiry}
          setCurrentExpiry={setCurrentExpiry}
          expiries={expiries}
          setStrategy={setStrategy}
          fetchSavedStrategy={fetchSavedStrategy}
          userSavedStrategys={userSavedStrategys}
          deleteSavedStrategy={deleteSavedStrategy}
          updateSavedStrategy={updateSavedStrategy}
          selectSavedStrategy={selectSavedStrategy}
          clearStrategy={clearStrategy}
        />
      </div>
    </div>
  );
}

export default SelectorPane;
