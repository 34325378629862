import { useState, useRef } from "react";
import "./UserLoadedStrategy.css";

import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { BsDash } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { AiFillWarning } from "react-icons/ai";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

function UserLoadedStrategy(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openExit, setOpenExit] = useState(false);
  const handleOpenExit = () => setOpenExit(true);
  const handleCloseExit = () => setOpenExit(false);
  const exitPrice = useRef(null);

  return (
    <div className="text-sm w-full">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className=" md:w-[350px] h-full text-center flex flex-col justify-center m-auto">
          <div className="bg-white px-12 py-12 flex flex-col">
            <h2 className="flex gap-1 justify-center items-center text-xl text-red-500">
              Warning <AiFillWarning className="text-yellow-500 text-xl" />
            </h2>{" "}
            <h2>Are you sure to Delete script ???</h2>
            <button
              onClick={() =>
                props.deleteScript(
                  props.scriptId,
                  props.positionId,
                  handleClose
                )
              }
              className="mt-8 border-[1px] bg-red-600 text-white py-2 rounded-md "
            >
              Delete Script
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={openExit}
        onClose={handleCloseExit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="text-center text-xl">Enter Exit Price</h1>
          <TextField
            id="outlined-basic"
            label="Exit Price"
            variant="outlined"
            sx={{ mt: 2, width: "100%" }}
            inputRef={exitPrice}
            defaultValue={props.currentContractPrice}
          />
          <button
            onClick={() =>
              props.exitScript(
                exitPrice.current.value,
                props.positionId,
                props.scriptId,
                handleCloseExit
              )
            }
            className="bg-green-600 w-full mt-6 py-2 rounded-md text-white font-semibold"
          >
            Sure Exit
          </button>
        </Box>
      </Modal>
      {/* Computer */}
      <div className="md:flex hidden  items-center md:w-[500px] text-[0.9rem] mb-4  ">
        <div className="flex-1">
          {" "}
          <input
            className="w-4 h-4 mr-2  text-sm "
            type="checkbox"
            checked={props.check}
            onChange={() => props.selectScript(props.id)}
          />
        </div>
        <div className="flex-1">
          <button
            className={`${props.direction === "B"
              ? "bg-[#68cae3] text-white"
              : "bg-red-500/40 text-red-500"
              } px-2 rounded-sm  font-semibold mr-3`}
            type="button"
          >
            {props.direction}
          </button>
        </div>

        <div className="flex-1">
          <select
            className="bg-transparent border-[1px] border-black/30 px-1 py-0.5 rounded-md mr-2"
            name="expiry"
            id="expiry"
          >
            <option className="bg-white" value="20 July">
              {props.expiry}
            </option>
          </select>
        </div>

        <div className=" flex-1 border-[1px]  content-center  border-black/30 flex items-center rounded-md mr-2">
          <button
            className="px-1  border-r-[1px] border-black/30   "
            type="button"
          >
            <BsDash className=" text-gray-500" />
          </button>
          <span className="px-2">{props.strike}</span>
          <button
            className="px-1  border-l-[1px] border-black/30"
            type="button "
          >
            <AiOutlinePlus className=" text-gray-500" />
          </button>
        </div>
        <div className="flex-1 border-[1px] border-black/30 rounded-md px-1.5 mr-2">
          {" "}
          <button className="" type="button">
            {props.type}
          </button>
        </div>

        <div className="flex-1">
          {" "}
          <input
            className="w-5 text-center py-0.5 outline-none border-[1px] border-black/30 rounded-md px-1 text-[0.85rem] mr-2 text-sm"
            defaultValue={props.quantity}
            disabled
          />
        </div>
        <div className="flex-1">
          {" "}
          <input
            className="w-12 text-center py-0.5 outline-none border-[1px] border-black/30 rounded-md px-1 text-[0.85rem] mr-2 text-sm"
            defaultValue={props.price}
            disabled
          />
        </div>
        <div className="flex-1">
          {" "}
          <input
            className="w-12 text-center py-0.5 outline-none border-[1px] border-black/30 rounded-md px-1 text-[0.85rem] mr-2 text-sm"
            defaultValue={props.currentContractPrice}
            disabled
          />
        </div>
        <div className="flex-1 mr-7">
          {" "}
          <button
            onClick={() => props.handleRemove(props.id)}
            className="button"
            type="button"
          >
            <FaTrash className="text-gray-600" />
          </button>
        </div>
      </div>
      {/* Computer */}
      {/* Mobile */}
      <div className="md:hidden flex items-center justify-between text-sm w-full md:w-[500px] text-[0.9rem] mb-4  ">
        <div className="">
          {" "}
          <input
            className="w-4 h-4 mr-1 "
            type="checkbox"
            checked={props.check}
            onChange={() => props.selectScript(props.id)}
          />
        </div>
        <div className="">
          <button
            className={`${props.direction === "B"
              ? "bg-[#68cae3] text-white"
              : "bg-red-500/40 text-red-500"
              } px-2 rounded-sm  font-semibold mr-2`}
            type="button"
          >
            {props.direction}
          </button>
        </div>

        <div className="">
          <select
            className="bg-transparent !text-xs w-20 border-[1px] border-black/30 px-1 py-1 rounded-md mr-1"
            name="expiry"
            id="expiry"
          >
            <option className="bg-white" value={props.expiry}>
              {props.expiry}
            </option>
          </select>
        </div>

        <div className="  !text-md border-[1px] !text-xs border-black/30 px-1 py-1   flex items-center rounded-md mr-1 ">
          <span className="flex mr-1">{props.strike}</span>
          {props.type}
        </div>

        <div className="">
          {" "}
          <input
            className=" bg-transparent w-5 border-[1px] !text-xs px-1 py-1 border-black/30 rounded-md mr-1"
            defaultValue={props.quantity}
          />
        </div>
        <div className="">
          {" "}
          <input
            className=" bg-transparent w-9  !text-xs border-[1px] px-1 py-[5px] border-black/30 rounded-md mr-1"
            defaultValue={props.price}
          />
        </div>
        <div className="">
          {" "}
          <input
            className="w-12 text-center   !text-xs py-[5px] outline-none border-[1px] border-black/30 rounded-md px-1 text-[0.85rem] mr-1"
            defaultValue={props.currentContractPrice}
          />
        </div>
        <div className="">
          {" "}
          <button
            onClick={() => props.handleRemove(props.id)}
            className="button"
            type="button"
          >
            <FaTrash className="text-gray-600" />
          </button>
        </div>
      </div>
      {/* Mobile */}

      <div className="statsScript">
        <button
          onClick={handleOpen}
          className="text-red-500 text-sm border-[1px] px-3 py-1 rounded-[5px] bg-red-500/20"
        >
          Delete
        </button>
        {props.exit !== null ? null : (
          <button
            onClick={handleOpenExit}
            className="text-green-700 text-sm border-[1px] px-3 py-1 rounded-[5px] bg-green-500/20 ml-1"
          >
            Exit
          </button>
        )}
        <span>Booked: {props.booked}</span>
        <span>Unrealized: {props.unrealized}</span>
      </div>
      <hr className="mb-2" />
    </div>
  );
}

export default UserLoadedStrategy;
