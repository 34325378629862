import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import savedStrategy from "./../../Data/readyMadeStrategies.json";
import { strategyScripts, monthNames, fetchExpiries } from "../../CalculationFunctions";
import { useContext, useState } from "react";
import { DataContext } from "../../Contexts/DataContext";
import { AuthContext } from "../../Contexts/Auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SavedStrategySummary from "./SavedStrategySummary";
import { Svg } from "../Svgs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SelectStrategy(props) {
  const { user } = useContext(AuthContext);
  const { Price } = useContext(DataContext);
  const [value, setValue] = useState(0);
  const [strategyCategory, setStrategyCategory] = useState("Bullish");
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      props.fetchSavedStrategy(user);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Ready Made" {...a11yProps(0)} sx={{ width: "50%" }} />
          <Tab
            label="Saved Strategies"
            {...a11yProps(1)}
            sx={{ width: "50%" }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className=" text-[12px] text-gray-600">
          Please Click on a saved Strategy to load it.
        </div>
        <div className="">
          <div className="flex mb-4 md:mt-0.5 flex-wrap md:flex-nowrap mt-2     items-center justify-evenly sm:justify-between    md:justify-between text-[13px]">
            <div
              onClick={() => setStrategyCategory("Bullish")}
              className={`${strategyCategory === "Bullish" &&
                "!bg-[#68cae3] text-white font-semibold"
                } md:px-4 px-2 py-1 text-black  rounded-xl border bg-[whitesmoke] mr-2 md:mr-0 border-gray-200   cursor-pointer italic  shadow-md hover:bg-[#68cae3] hover:text-white`}
            >
              <p>Bullish</p>
            </div>
            <div
              onClick={() => setStrategyCategory("Bearish")}
              className={`${strategyCategory === "Bearish" &&
                "!bg-[#68cae3] text-white font-semibold"
                } md:px-4 px-2 py-1 text-black   rounded-xl border bg-[whitesmoke] border-gray-200 mr-2 md:mr-0   cursor-pointer italic  shadow-md hover:bg-[#68cae3] hover:text-white`}
            >
              <p>Bearish</p>
            </div>
            <div
              onClick={() => setStrategyCategory("Neutral")}
              className={`${strategyCategory === "Neutral" &&
                "!bg-[#68cae3] text-white font-semibold"
                }  md:px-4 px-2 py-1 text-black   rounded-xl border bg-[whitesmoke] border-gray-200 mr-2 md:mr-0   cursor-pointer italic  shadow-md hover:bg-[#68cae3] hover:text-white`}
            >
              <p>Neutral</p>
            </div>
            <div
              onClick={() => setStrategyCategory("Other")}
              className={`${strategyCategory === "Other" &&
                "!bg-[#68cae3] text-white font-semibold"
                }  md:px-4 px-2 py-1 text-black   rounded-xl border bg-[whitesmoke] border-gray-200 mr-2 md:mr-0   cursor-pointer italic  shadow-md hover:bg-[#68cae3] hover:text-white`}
            >
              <p>Other</p>
            </div>
            <div className="flex flex-col  justify-self-start mt-3 md:mt-0 items-center bg-transparent ">
              <p className="italic  text-gray-600">Expiry </p>
              <select
                name="expiry"
                id="expiry"
                value={props.currentExpiry}
                onChange={(e) => props.setCurrentExpiry(e.target.value)}
                className="bg-transparent px-2 py-1 border-black/30 border-[1px] rounded-md"
              >
                {props.expiries.map((expiry, index) => (
                  <option value={expiry} key={index}>
                    {new Date(expiry).getUTCDate() +
                      " " +
                      monthNames[new Date(expiry).getMonth()]}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex w-full justify-start cursor-pointer flex-wrap gap-2">
            {savedStrategy.map(
              (strategy, i) =>
                strategy.Tag === strategyCategory && (
                  <div
                    onClick={() =>
                      strategyScripts(
                        strategy.Scripts,
                        Price,
                        "BTCUSD",
                        props.currentExpiry
                      ).then((result) => {
                        props.clearStrategy();
                        props.setStrategy(result);
                      })
                    }
                    key={i}
                    className="flex flex-col md:max-w-[96px] md:min-w-[96px] md:max-h-[100px] md:min-h-[100px] break-words   flex-[50%] text-sm italic text-black justify-between items-center rounded-md md:flex-1 border-gray-300 border-[1px] p-1 py-3 hover:bg-gray-300 text-center"
                  >
                    <div key={i} className="my-1">
                      <Svg name={savedStrategy[i].StrategyName} />
                    </div>
                    <div className="text-[12px] leading-[14px] ">
                      {savedStrategy[i].StrategyName}
                    </div>
                  </div>
                )
            )}
          </div>
          <div className="text-center text-sm capitalize italic mb-2 text-gray-600 mt-2">
            Or Create your Custom Strategy Now and Save in Saved Strategies !
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className=" w-[75vw] md:flex md:w-full justify-center">
          {Object.keys(user).length === 0 ? (
            <p
              onClick={() => navigate("/login")}
              className="text-center bg-[#68cae3] hover:bg-[#1db3d9] transition cursor-pointer py-2 rounded-md text-white font-semibold px-4"
            >
              Login to see saved strategy
            </p>
          ) : props.userSavedStrategys.length === 0 ? (
            <p className="text-center capitalize ">Save Some Strategies</p>
          ) : (
            props.userSavedStrategys.map((userStrategy) => (
              <SavedStrategySummary
                key={userStrategy.id}
                id={userStrategy.id}
                strategyName={userStrategy.strategy}
                scriptNumber={userStrategy.ScriptsInstances.length}
                deleteSavedStrategy={props.deleteSavedStrategy}
                updateSavedStrategy={props.updateSavedStrategy}
                selectSavedStrategy={props.selectSavedStrategy}
              />
            ))
          )}
        </div>
      </TabPanel>
    </Box>
  );
}
