import React from "react";

import { lotsDropdown } from "../../CalculationFunctions";
import { BsDash } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";

function Scripts(props) {
  return (
    <>
      {/* Desktop */}
      <div className="md:flex hidden items-center w-full md:w-[500px]  md:text-[0.9rem] mb-4 ">
        <div className="flex-1">
          {" "}
          <input
            className="w-4 h-4 mr-2 "
            type="checkbox"
            checked={props.check}
            onChange={() => props.selectScript(props.id)}
          />
        </div>
        <div className="flex-1">
          <button
            className={`${props.direction === "B"
              ? "bg-[#68cae3] text-white"
              : "bg-red-500/40 text-red-500"
              } px-2 rounded-sm  font-semibold mr-3`}
            type="button"
            onClick={() => props.reverseScriptDirection(props.id)}
          >
            {props.direction}
          </button>
        </div>

        <div className="flex-1">
          <select
            className="bg-transparent border-[1px] border-black/30 px-1 py-0.5 rounded-md mr-2"
            name="expiry"
            id="expiry"
          >
            <option className="bg-white" value={props.expiry}>
              {props.expiry}
            </option>
          </select>
        </div>

        <div className=" flex-1 border-[1px]  content-center  border-black/30 flex items-center rounded-md mr-2">
          <button
            className="px-1  border-r-[1px] border-black/30   "
            type="button"
          >
            <BsDash className=" text-gray-500" />
          </button>
          <span className="px-2">{props.strike}</span>
          <button
            className="px-1  border-l-[1px] border-black/30"
            type="button "
          >
            <AiOutlinePlus className=" text-gray-500" />
          </button>
        </div>
        <div className="flex-1 border-[1px] border-black/30 rounded-md px-1.5 mr-2">
          {" "}
          <button className="" type="button">
            {props.type}
          </button>
        </div>
        <div className="flex-1">
          <select
            className="w-10 bg-transparent border-[1px] py-0.5 border-black/30 rounded-md mr-2"
            value={props.quantity}
            onChange={(e) => props.updateScriptLots(props.id, e.target.value)}
          >
            <option value={props.quantity}>{props.quantity}</option>
            {lotsDropdown(props.quantity)}
          </select>
        </div>
        <div className="flex-1">
          {" "}
          <input
            className="w-16 text-center py-0.5 outline-none border-[1px] border-black/30 rounded-md px-1 text-[0.85rem] mr-3"
            defaultValue={props.price}
            onChange={(e) =>
              props.updateScriptPremium(props.id, e.target.value)
            }
          />
        </div>
        <div className="flex-1 mr-7">
          {" "}
          <button
            onClick={() => props.handleRemove(props.id)}
            className="button"
            type="button"
          >
            <FaTrash className="text-gray-600" />
          </button>
        </div>
      </div>
      {/* Desktop */}
      {/* Mobile */}
      <div className="flex md:hidden justify-around items-center !text-xs w-full md:w-[500px] text-[0.9rem] mb-4 ">
        <div className="">
          {" "}
          <input
            className="w-4 h-4 mr-2 "
            type="checkbox"
            checked={props.check}
            onChange={() => props.selectScript(props.id)}
          />
        </div>
        <div className="">
          <button
            className={`${props.direction === "B"
              ? "bg-[#68cae3] text-white"
              : "bg-red-500/40 text-red-500"
              } px-2 rounded-sm  font-semibold mr-2`}
            type="button"
            onClick={() => props.reverseScriptDirection(props.id)}
          >
            {props.direction}
          </button>
        </div>

        <div className="">
          <select
            className="bg-transparent w-20 border-[1px] border-black/30 px-1 py-0.5 rounded-md mr-1"
            name="expiry"
            id="expiry"
          >
            <option className="bg-white" value={props.expiry}>
              {props.expiry}
            </option>
          </select>
        </div>

        <div className="  !text-md border-[1px] border-black/30 px-1 py-0.5   flex items-center rounded-md mr-1 ">
          <span className="flex mr-1">{props.strike}</span>
          {props.type}
        </div>
        <div className="">
          <select
            className=" bg-transparent w-10 border-[1px] px-1 py-0.5 border-black/30 rounded-md mr-1"
            value={props.quantity}
            onChange={(e) => props.updateScriptLots(props.id, e.target.value)}
          >
            <option className="" value={props.quantity}>
              {props.quantity}
            </option>
            {lotsDropdown(props.quantity)}
          </select>
        </div>
        <div className="">
          {" "}
          <input
            className="w-12 text-center  text-sm py-0.5 outline-none border-[1px] border-black/30 rounded-md px-1 text-[0.85rem] mr-2"
            defaultValue={props.price}
            onChange={(e) =>
              props.updateScriptPremium(props.id, e.target.value)
            }
          />
        </div>
        <div className="">
          {" "}
          <button
            onClick={() => props.handleRemove(props.id)}
            className="button"
            type="button"
          >
            <FaTrash className="text-gray-600" />
          </button>
        </div>
      </div>
    </>
  );
}

export default Scripts;
