import React, { createContext, useState, useEffect } from "react";
import { toast } from "react-hot-toast";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState({});

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("user"));
    if (items) {
      setUser(items);
    }
  }, []);

  const userLogin = (user) => {
    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
  };

  const userLogout = () => {
    setUser({});

    localStorage.removeItem("user");
    toast.success("Logout Successful");
  };

  const checkPro = (expireDate) => {
    if (new Date().getTime() < new Date(expireDate).getTime()) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ user, userLogin, userLogout, checkPro }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext };
