import { DiGoogleAnalytics } from "react-icons/di";

function EmptyStrategy(props) {
  const { setVisible } = props;
  return (
    <div
      className="my-4 pt-0 pb-4 w-full   relative md:w-[500px] px-4 bg-white"
      onClick={() => setVisible(true)}
    >
      <div>
        <DiGoogleAnalytics className="text-[150px] absolute -translate-y-2/4 -translate-x-2/4 top-[40%] left-[50%] text-[#68cae3] " />
      </div>
      <p className="mt-4 text-center italic text-gray-600">
        No Strategy or Trades Added{" "}
      </p>
      <button className="bg-[#68cae3] px-8 py-3 hover:bg-[#8647b9] flex mx-auto mt-[180px] rounded-md text-white font-semibold transition">
        Build a new Stategy
      </button>
    </div>
  );
}

export default EmptyStrategy;
