import { createContext, useState, useEffect, useRef } from "react";
import axios from "axios";

const DataContext = createContext();

export function DataProvider({ children }) {
  const [Price, setPrice] = useState([]);
  const [Volatility, setVolatility] = useState([]);
  const [Coin, setCoin] = useState(null);
  const [lastPrice, setLastPrice] = useState(null);
  const [infoData, setInfoData] = useState(null);

  // async function latestData() {
  //   await axios.get("https://www.adonis.xyz/api/updateasset");
  // }

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  async function fetchData() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/fetchasset`)
      .then((response) => {
        setInfoData({
          high: response.data.priceRecords[0].high,
          low: response.data.priceRecords[0].low,
          lastPrice: response.data.priceRecords[0].lastPrice,
          openInterest: response.data.priceRecords[0].openInterest,
          priceChange: response.data.priceRecords[0].priceChange,
          volumeBtc: response.data.priceRecords[0].volumeBtc,
          volumeUsd: response.data.priceRecords[0].volumeUsd,
          futurePrice: response.data.priceRecords[0].futurePrice,
        });
        setCoin(response.data.priceRecords[0].pair);
        setPrice(response.data.priceRecords[0].price);
        setVolatility(response.data.priceRecords[0].volatility);
        setLastPrice(response.data.priceRecords[0].lastPrice);
      });
  }

  useInterval(() => {
    fetchData();
  }, 336000);

  useEffect(() => {
    // latestData();
    fetchData();
  }, []);
  return (
    <DataContext.Provider
      value={{ Price, Volatility, lastPrice, Coin, infoData }}
    >
      {children}
    </DataContext.Provider>
  );
}

export { DataContext };
