import React, { useContext } from "react";

import { StrategyContext } from "../Contexts/StrategyContext";

function Strike(props) {
  const { StrategyFor, updateStrategy } = useContext(StrategyContext);

  // function AddScriptToStrategy(script, direction, type) {
  //   const data = [];
  //   const premium = type === "CALL" ? script.callPremium : script.putPremium;
  //   data.push({
  //     id: StrategyFor.length,
  //     StrikePrice: script.StrikePrice,
  //     Direction: direction,
  //     Symbol: script.symbol,
  //     Type: type,
  //     Expiry: script.expiry,
  //     lots: 1,
  //     check: true,
  //     Premium: premium,
  //   });

  //   var arr3 = [...StrategyFor, ...data];
  //   updateStrategy(arr3);
  // }

  const checkId = (StrategyFor) => {
    let i = 0;
    if (StrategyFor.length < 1) {
      return i;
    } else {
      StrategyFor?.map((item) => {
        if (item.id > i) {
          i = item.id;
        }
      });
      return i + 1;
    }
  };

  function AddScriptToStrategy(script, direction, type) {
    const data = [];

    const premium = type === "CALL" ? script.callPremium : script.putPremium;
    const delta = type === "CALL" ? script.callDelta : script.putDelta;
    const theta = type === "CALL" ? script.callTheta : script.putTheta;
    const rho = type === "CALL" ? script.callRho : script.putRho;
    const gamma = type === "CALL" ? script.callGamma : script.putGamma;
    const vega = type === "CALL" ? script.callVega : script.putVega;

    const found = StrategyFor.some(
      (el) =>
        el.StrikePrice === script.StrikePrice &&
        el.Type === type &&
        el.Expiry === script.expiry
    );

    if (!found) {
      data.push({
        id: checkId(StrategyFor),
        StrikePrice: script.StrikePrice,
        Direction: direction,
        Symbol: script.symbol,
        Type: type,
        Expiry: script.expiry,
        lots: 1,
        check: true,
        Premium: premium,
        Delta: delta,
        Theta: theta,
        Rho: rho,
        Gamma: gamma,
        Vega: vega,
      });
    }

    var arr3 = [...StrategyFor, ...data];
    // const index = arr3.findIndex((object) => {
    //   return (
    //     (object.StrikePrice === script.StrikePrice &&
    //       object.Direction === "buy") ||
    //     object.Direction === "sell"
    //   );
    // });

    if (found) {
      let index = arr3.findIndex((item) => {
        return (
          item.StrikePrice === script.StrikePrice &&
          item.Type === type &&
          item.Expiry === script.expiry
        );
      });
      if (arr3[index].Direction === direction) {
        arr3.splice(index, 1);
      } else {
        arr3.splice(index, 1, {
          id: StrategyFor[index].id,
          StrikePrice: script.StrikePrice,
          Direction: direction,
          Symbol: script.symbol,
          Type: type,
          Expiry: script.expiry,
          lots: 1,
          check: true,
          Premium: premium,
          Delta: delta,
          Theta: theta,
          Rho: rho,
          Gamma: gamma,
          Vega: vega,
        });
      }
    }
    // console.log(arr3);
    updateStrategy(arr3);
  }

  const checkTrue = (type, CRP, strikePrice) => {
    if (StrategyFor.length > 0) {
      const found = StrategyFor.some(
        (el) =>
          el.StrikePrice === strikePrice &&
          el.Type === CRP &&
          el.Direction === type &&
          el.Expiry === props.expiry
      );
      if (found) {
        return true;
      }
    }
  };

  return (
    <>
      <div className="flex border-[1px] border-gray-400/30 px-2 py-1 ">
        <div className="basis-2/5 italic  flex-col md:flex-row flex mt-1 green">
          <div className="basis-2/4 text-left">{props.callPremium}</div>
          <button
            className={`basis-1/4 border-[1px] m-1 border-gray-400/70 mx-3 hover:bg-green-300 transition-all duration-300 ease-in-out  ${checkTrue("buy", "CALL", props.StrikePrice) && "bg-green-300"
              }`}
            onClick={() => AddScriptToStrategy(props, "buy", "CALL")}
          >
            B
          </button>
          <button
            className={`basis-1/4 border-[1px] m-1 border-gray-400/70 mx-3 transition-all duration-300 ease-in-out hover:bg-red-300  ${checkTrue("sell", "CALL", props.StrikePrice) && "bg-red-300"
              }`}
            onClick={() => AddScriptToStrategy(props, "sell", "CALL")}
          >
            S
          </button>
        </div>

        <div className="basis-1/5 text-black font-semibold text-center">
          {" "}
          <div>{props.StrikePrice}</div>
        </div>

        <div className="basis-2/5 italic flex-col md:flex-row flex mt-1 red">
          <div className="basis-2/4 md:hidden text-right">
            {props.putPremium ? props.putPremium : "---"}
          </div>
          <button
            className={`basis-1/4 border-[1px] m-1 border-gray-400/70 mx-3 hover:bg-green-300  ${checkTrue("buy", "PUT", props.StrikePrice) && "bg-green-300"
              }`}
            onClick={() => AddScriptToStrategy(props, "buy", "PUT")}
          >
            B
          </button>
          <button
            className={`basis-1/4 border-[1px] m-1 border-gray-400/70 mx-3 hover:bg-red-300  ${checkTrue("sell", "PUT", props.StrikePrice) && "bg-red-300"
              }`}
            onClick={() => AddScriptToStrategy(props, "sell", "PUT")}
          >
            S
          </button>
          <div className="basis-2/4 hidden md:inline-block text-right">
            {props.putPremium ? props.putPremium : "---"}
          </div>
        </div>
      </div>
    </>
  );
}

export default Strike;
