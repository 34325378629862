import { Link } from "react-router-dom";
import { FaUserAlt, FaUserPlus } from "react-icons/fa";
import { AuthContext } from "../Contexts/Auth";
import { useContext } from "react";
import { motion } from "framer-motion";
import React from "react";
import { useState } from "react";
import discord from ".././assets/discord.png";
import PNSB from ".././assets/PNSB.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Drawer } from "@mui/material";
import {
  ArrowLeftOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import Avatar from "react-avatar";

function Navbar() {
  const Drawerstye = {
    right: 0,
    top: 0,
    height: "100%",
    width: "60%",
    boxShadow: "none",
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const { user, userLogout, checkPro } = useContext(AuthContext);
  const [show, setShow] = useState(true);

  return (
    <div className="fixed top-0 left-0 right-0 z-50 w-full bg-white">
      <div className="border-b-[1px] shadow-lg shadow-slate-10 hidden md:block lg:block transition-all duration-200 ease-in-out ">
        <nav className="flex items-center justify-between gap-6 p-4 transition-all duration-300 ease-in-out lg:mx-24">
          <Link to="/">
            <div>
              <img src={PNSB} alt="Girl in a jacket" width={175} />
            </div>
          </Link>

          <div className="flex relative gap-6">
            <div>
              <a
                href="https://discord.com/invite/9zubxeWKet"
                target="_blank"
                rel="noreferrer"
              >
                <button className="flex items-center gap-2 border-[#68cae3] hover:scale-105  transition-all duration-200  rounded-md italic active:scale-95 px-3 py-1.5 border ">
                  <img src={discord} className="w-6 h-5 mt-1" alt="" />
                </button>
              </a>
            </div>
            {user.name ? (
              <div className="flex group items-center  gap-3">
                {!user.pro && !checkPro(user?.pro_expiry) && (
                  <Link to="/profile">
                    <div>
                      <p className="text-[#68cae3] inline-flex hover:text-white border border-[#68cae3] hover:bg-[#68cae3] focus:ring-4 focus:outline-none focus:ring-[#68cae3] font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center gap-1 cursor-pointer  dark:border-[#68cae3] dark:text-[#68cae3] dark:hover:text-white dark:hover:bg-[#68cae3] dark:focus:ring-[#68cae3] transition">
                        {" "}
                        Upgrade Pro
                      </p>
                    </div>
                  </Link>
                )}

                <p
                  className="flex items-center cursor-pointer font-semibold italic mx-3 gap-2"
                  onClick={() => setShow(!show)}
                >
                  <Avatar
                    className="rounded-full !h-8 !w-8 text-sm"
                    name={user?.name}
                  />{" "}
                  {user?.name}{" "}
                  {show ? (
                    <ChevronDownIcon className="w-6 h-6" />
                  ) : (
                    <ChevronUpIcon className="w-6 h-6" />
                  )}
                </p>

                <motion.div
                  initial={{
                    y: -50,
                    opacity: 0,
                  }}
                  whileInView={{
                    y: 0,
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                  }}
                  className={`${show ? "hidden" : "inline-block"
                    }  absolute   bg-[whitesmoke] top-12 w-[200px]   -right-4 border border-black/20 shadow-md`}
                >
                  <div className={`${show ? "hidden" : "flex"}  flex-col`}>
                    <div className="hover:bg-gray-200 bg-white transition-all duration-200 ease-in-out cursor-pointer">
                      <Link to="/profile">
                        <p
                          className="py-2 inline-flex items-center gap-1 justify-center w-full h-full text-md italic text-gray-600   text-center border-b  border-black/10 "
                          onClick={(e) => setShow(!show)}
                        >
                          <UserCircleIcon className="w-6 h-6 text-black" />
                          Account Details
                        </p>
                      </Link>
                    </div>
                    <div className="hover:bg-gray-200 bg-white transition-all duration-200 ease-in-out cursor-pointer">
                      <a
                        href="https://discord.com/invite/9zubxeWKet"
                        target="_blank"
                        rel="noreferrer"
                        className="py-2 inline-flex items-center gap-1  justify-center w-full h-full text-md italic text-gray-600   text-center border-b  border-black/10 "
                      >
                        <UserGroupIcon className="w-6 h-6 text-black" />
                        Contact Support
                      </a>
                    </div>
                    <div
                      onClick={() => {
                        setShow(!show);
                      }}
                    >
                      {" "}
                      <button
                        onClick={userLogout}
                        class="  py-2 inline-flex items-center gap-2 justify-center w-full h-full bg-white transition-all  ease-in-out cursor-pointer   text-red-600 hover:bg-red-600 hover:text-red-100 duration-300"
                      >
                        {" "}
                        <ArrowLeftOnRectangleIcon className="w-6 h-6" />{" "}
                        <Link to="/" className="">
                          Logout
                        </Link>
                      </button>
                    </div>
                  </div>
                </motion.div>
              </div>
            ) : (
              <>
                <div className=" border-2 border-[#68cae3] p-2 text-black rounded-[5px] font-semibold shadow-lg shadow-slate-200 hover:bg-[#68cae3]/100 hover:text-white transition-all duration-300 ease-in-out">
                  <Link to="/signup">
                    <p className="flex items-center gap-2 px-2 ">
                      {" "}
                      <FaUserPlus /> Register
                    </p>
                  </Link>
                </div>
                <div className="bg-[#68cae3] p-2 text-white rounded-[5px] font-semibold shadow-lg shadow-slate-200 transition-all duration-300 ease-in-out hover:bg-[#8647b9]">
                  <Link to="/login">
                    <p className="flex items-center gap-2 px-2">
                      {" "}
                      <FaUserAlt /> Login{" "}
                    </p>
                  </Link>
                </div>
              </>
            )}
          </div>
        </nav>
      </div>
      <div className="border-b-[1px] shadow-sm shadow-slate-200  md:hidden lg:hidden ">
        <nav className="flex items-center justify-between gap-4 p-4 transition-all duration-300 ease-in-out lg:mx-24">
          <Link to="/">
            <div>
              <img src={PNSB} alt="strike bet logo" width={150} />
            </div>
          </Link>

          <div className="flex gap-1">
            <div
              className="p-2 hover:text-[#68cae3] text-sm "
              onClick={() => setOpen(!open)}
            >
              {open ? <CloseIcon /> : <MenuIcon />}
            </div>
            <Drawer
              anchor="right"
              open={open}
              onClose={handleClose}
              PaperProps={{ sx: Drawerstye }}
              style={{ zIndex: 1500 }}
            >
              <div className="flex w-full mt-4 flex-col  border-b-[1px] ">
                {user.name ? (
                  <>
                    <div className="border-b-[1px]">
                      <h1 className="text-green-500 italic text-center text-xl">
                        Welcome !
                      </h1>
                      <p className="text-center italic pb-2 font-semibold uppercase">
                        {user?.name}
                      </p>
                    </div>
                    <div className="py-4 px-6 hover:bg-gray-100 w-[100%] font-medium  border-b-[1px] ">
                      <Link to="/profile">
                        <p className="flex items-center gap-2 px-2 italic text-gray-600 ">
                          {" "}
                          <Avatar
                            className="rounded-full !h-8 !w-8 text-sm"
                            name={user?.name}
                          />{" "}
                          Profile Settings
                        </p>
                      </Link>
                    </div>
                    <div
                      className="border-b-[1px]"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      {" "}
                      <button
                        onClick={userLogout}
                        class="  py-2 inline-flex items-center gap-2 justify-center w-full h-full bg-white transition-all  ease-in-out cursor-pointer   text-red-600 hover:bg-red-600 hover:text-red-100 duration-300"
                      >
                        {" "}
                        <ArrowLeftOnRectangleIcon className="w-6 h-6" />{" "}
                        <Link to="/" className="">
                          Logout
                        </Link>
                      </button>
                    </div>
                    {!user.pro && !checkPro(user?.pro_expiry) && (
                      <div className=" bg-white w-full transition-all duration-200 ease-in-out text-[#68cae3]  mx-auto inline-flex hover:text-white border hover:bg-[#68cae3] focus:ring-4 focus:outline-none focus:ring-[#68cae3] font-medium rounded-lg text-sm px-5 py-2.5   gap-1 cursor-pointer  dark:border-[#68cae3] dark:text-[#68cae3] dark:hover:text-white dark:hover:bg-[#68cae3] dark:focus:ring-[#68cae3]">
                        <Link to="/profile">
                          <div>Upgrade Pro</div>
                        </Link>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="py-4 px-6 hover:bg-gray-100 w-[100%] font-medium border-b-[1px] ">
                      <Link to="/login">
                        <p className="flex items-center gap-3 px-2 ">
                          {" "}
                          <FaUserAlt /> Login{" "}
                        </p>
                      </Link>
                    </div>
                    <div className="py-4 px-6 hover:bg-gray-100 w-[100%] font-medium  border-b-[1px] ">
                      <Link to="/signup">
                        <p className="flex items-center gap-2 px-2 ">
                          {" "}
                          <FaUserPlus /> Register
                        </p>
                      </Link>
                    </div>
                  </>
                )}
                <div className="hover:bg-gray-200 bg-white transition-all duration-200 ease-in-out cursor-pointer">
                  <a
                    href="https://discord.com/invite/9zubxeWKet"
                    target="_blank"
                    rel="noreferrer"
                    className="py-2 inline-flex items-center gap-2  justify-center w-full h-full text-md italic text-gray-600   text-center border-b  border-black/10 "
                  >
                    <img src={discord} className="w-6 h-5 mt-1" alt="" />
                    Contact Support
                  </a>
                </div>
              </div>
            </Drawer>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
