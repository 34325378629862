import { Link, useLocation } from "react-router-dom";

import { FaRegEnvelope, FaUserAlt } from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";
import { motion } from "framer-motion";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../Contexts/Auth";
import { Blocks } from "react-loader-spinner";
import { Modal } from "@mui/material";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid";

export default function SignUp() {
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(null);
  const { userLogin } = useContext(AuthContext);

  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      if (state.emailVerification) {
        setOpen(true);
      }
    }
  }, [state]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/signup`, {
        name: name,
        email: email,
        nakedpassword: password,
      })
      .then(() => {
        toast.dismiss();
        setLoading(false);
        toast.success("Account Created Successful");
        setOpen(true);
      })
      .catch((error) => {
        toast.dismiss();
        setLoading(false);
        console.log(error); // Handle Signup Error
        if (error.response.status === 400) {
          toast.error("User Already Exits");
        } else if (error.response.status === 300) {
          toast.success("Please Verify Your Account");
          setOpen(true);
        } else {
          toast.error("Something Went Wrong");
        }
      });
  };

  const otpRef = useRef();

  const handleOtp = async (event) => {
    event.preventDefault();

    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/verifyOtp`, {
        email: email ? email : state.email,
        otp: otpRef.current.value,
      })
      .then((response) => {
        navigate("/login", {
          state: {
            emailVerification: false,
          },
        });
        setOpen(false);
        toast.success("Verification Successful");
      })
      .catch((error) => {
        toast.dismiss();
        console.log(error); // Handle Login Error
        if (error.response.status === 400) {
          toast.error("Please Enter Correct OTP");
        } else {
          toast.error("Something Went Wrong");
        }
      });
  };

  const googleHandleSubmit = async (event) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/googlelogin`, {
        event,
      })
      .then((response) => {
        toast.dismiss();
        userLogin({
          token: response.data.token,
          name: response.data.name,
          email: response.data.email,
        });
        navigate("/");
        toast.success("Login Successful");
      })
      .catch((error) => {
        toast.dismiss();
        console.log(error); // Handle Login Error
        toast.error("User Login Failed");
      });
  };

  return (
    <>
      <motion.div className="flex flex-col items-center justify-center min-h-[95vh] py-2 overflow-y-hidden bg-gray-100 mt-16 ">
        <div className="flex flex-col items-center justify-center flex-1 w-full p-2 text-center md:px-20">
          <motion.div
            initial={{
              x: 500,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1,
            }}
            className="flex flex-col w-full bg-white rounded-sm shadow-xl md:max-w-4xl md:flex-row "
          >
            <div className="md:w-2/5 px-12 text-white gradientBg rounded-tr-sm rounded-br-sm py-10  md:hidden">
              <h2 className="mb-2 text-3xl font-bold ">Already Registered? </h2>
              <div className="inline-block w-10 mb-2 border-2 border-white"></div>
              <p className="mb-8">
                Fill up the personal information and start journey with us.{" "}
              </p>
              <Link
                to="/login"
                className="inline-block px-12 py-2 font-semibold transition-all duration-300 ease-in-out border-2 border-white rounded-lg hover:border-black hover:bg-white hover:text-[black] "
              >
                Login
              </Link>
            </div>
            <div className="p-5 rounded-tl-sm rounded-bl-sm md:w-3/5">
              <div className="text-lg font-bold tracking-wide text-left">
                <img
                  src="/PNSB.png"
                  alt="STRIKEBET"
                  className="w-[100px] object-contain"
                />
              </div>
              <div className="py-10 ">
                <div className="flex flex-col justify-center items-center">
                  <h2 className="mb-2 text-3xl font-bold ">Register Now</h2>
                  <div className="inline-block w-10 mb-2 border-2 border-black"></div>
                </div>

                <div className="inline-flex items-center gap-3 px-6 pt-2 font-semibold my-2 transition-all duration-300 ease-in-out  rounded-sm    hover:text-[black] ">
                  <GoogleLogin
                    text="signup_with"
                    onSuccess={async (credentialResponse) => {
                      const decode = jwt_decode(credentialResponse.credential);
                      googleHandleSubmit(decode);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>

                <p className="text-sm text-gray-500 capitalize py-2 font-semibold">
                  Or use Email Id
                </p>
                <div className="flex flex-col items-center ">
                  <div className="flex items-center w-64 p-2 mb-4 bg-gray-100">
                    <FaUserAlt className="m-2 text-gray-500" />
                    <input
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      name="name"
                      autoComplete="name"
                      placeholder="UserName"
                      required
                      className="flex-1 bg-transparent outline-none"
                    />
                  </div>
                  <div className="flex items-center w-64 p-2 mb-4 bg-gray-100">
                    <FaRegEnvelope className="m-2 text-gray-500" />
                    <input
                      type="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      label="Email Address"
                      autoComplete="email"
                      placeholder="Email"
                      required
                      className="flex-1 bg-transparent outline-none"
                    />
                  </div>
                  <div className="flex items-center w-64 p-2 bg-gray-100">
                    <MdLockOutline className="m-2 text-gray-500" />
                    <input
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      name="password"
                      label="Password"
                      placeholder="Password"
                      id="password"
                      autoComplete="new-password"
                      required
                      className="flex-1 bg-transparent outline-none"
                    />
                  </div>

                  {loading ? (
                    <button className="inline-block px-12 py-2 font-semibold my-2 transition-all duration-300 ease-in-out border-2 rounded-sm border-[#68cae3]  hover:border-black hover:text-[black] ">
                      <Blocks
                        height="25"
                        width="50"
                        color="#68cae3"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </button>
                  ) : (
                    <button
                      onClick={handleSubmit}
                      className="inline-block px-12 py-2 font-semibold my-2 transition-all duration-300 ease-in-out border-2 rounded-lg border-[#68cae3]  hover:text-white hover:bg-[#68cae3]"
                    >
                      Register
                    </button>
                  )}
                </div>
              </div>
            </div>
            <motion.div
              initial={{
                y: 200,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                duration: 1,
              }}
              className="md:w-2/5 hidden px-12 text-white gradientBg rounded-tr-sm rounded-br-sm py-36  md:inline-block "
            >
              <h2 className="mb-2 text-3xl font-bold ">Already Registered?</h2>
              <div className="inline-block w-10 mb-2 border-2 border-white"></div>
              <p className="mb-8">
                Fill up the personal information and start journey with us.{" "}
              </p>
              <Link
                to="/login"
                className="inline-block px-12 py-2 font-semibold transition-all duration-300 ease-in-out border-2 border-white rounded-lg hover:border-black hover:bg-white hover:text-[black] "
              >
                Login
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </motion.div>

      <Modal open={open} onClose={(e) => { }}>
        <div className="absolute outline-none w-[600px]  shadow-lg bg-[whitesmoke] border border-black/20  top-[20%] left-[50%] -translate-x-[50%]">
          <div className="flex relative w-full h-full flex-col p-6  items-center">
            <p className="text-black font-semibold capitalize text-xl italic ">
              Verify Your Account
            </p>
            <div className="border mb-4 border-[#68cae3] w-[20%] mt-0.5" />
            <ArrowRightOnRectangleIcon
              color="black"
              className="w-[150px] h-[150px] text-gray-700 mb-3"
            />
            <p className="text-gray-600 italic mb-2 text-center">
              Enter The Otp Sent To Email
            </p>
            <div className="w-full flex justify-center items-center">
              <input
                type="text"
                ref={otpRef}
                placeholder="Enter Otp"
                className="px-3 py-1.5 border  text-gray-600 border-black/30 focus:text-black placeholder:text-gray-400 outline-none placeholder:font-semibold placeholder:text-sm placeholder:text-center rounded-md shadow-lg mb-3"
              />
            </div>

            <button
              className="bg-[#68cae3] px-8 py-1.5 text-white rounded-[5px] font-semibold shadow-lg shadow-slate-200 transition-all duration-300 ease-in-out"
              onClick={handleOtp}
            >
              Verify Email
            </button>
            <p className="text-sm italic text-gray-500 my-2">
              Check Your Spam/Junk folder in Email
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
}
