import React, { useContext } from "react";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { DataContext } from "../../Contexts/DataContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin
);

function Visualize(props) {
  const lowestpnlobject = props.chartData.reduce((min, current) =>
    current.pnl < min.pnl ? current : min
  );
  const { Price } = useContext(DataContext);
  // console.log(
  //   `chardData------ ${(lowestpnlobject.pnl, lowestpnlobject.price)}`
  // );
  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: "Options Payoff Chart",
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
      x: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    parsing: {
      xAxisKey: "price",
      yAxisKey: "pnl",
    },
  };
  const filteredData1 = props.chartData.filter((item) => item.pnl >= 0);
  const filteredData2 = props.chartData.filter((item) => item.pnl < 0);

  const data = {
    datasets: [
      {
        label: "Payoff",
        data: filteredData1,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(0, 255, 0, 0.5)",
        fill: true,
        yAxisID: "y",
      },
      {
        label: "Payoff",
        data: filteredData2,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 0, 0, 0.5)",
        fill: true,
        yAxisID: "y",
      },
      {
        label: `Current Price : ${Price}`,
        data: [
          {
            price: Price,
            pnl: Math.max(...props.chartData.map((item) => item.pnl)),
          },
          {
            price: Price,
            pnl: Math.min(...props.chartData.map((item) => item.pnl)),
          },
        ],
        borderColor: "green",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        xAxisID: "x",
      },
      {
        label: `Price Axis`,
        data: [
          {
            price: 0,
            pnl: 0,
          },
          {
            price: 100000,
            pnl: 0,
          },
        ],
        borderColor: "#68cae3",
        backgroundColor: "#68cae3",
        xAxisID: "x",
      },
    ],
  };

  return (
    <div className="bg-white md:h-auto   md:w-auto overflow-scroll   p-2 mb-5 ">
      {" "}
      <div className="w-[700px]  md:w-auto ">
        {" "}
        <Line options={options} data={data} />
      </div>
    </div>
  );
}

export default Visualize;
