import { useContext, useState, useRef } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { AuthContext } from "../../Contexts/Auth";
import Modal from "@mui/material/Modal";
import { AiFillEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 6,
  borderRadius: "10px",
};

export default function SavedStrategySummary(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { user } = useContext(AuthContext);
  const strategyName = useRef(null);
  return (
    <div className="flex flex-col w-full text-left border-b-[1px] py-2  px-2">
      <div
        onClick={() => props.selectSavedStrategy(props.id)}
        className="cursor-pointer flex justify-between items-center"
      >
        <div>
          <p className="text-md capitalize ">{props.strategyName}</p>
          <p className="text-gray-600">{`${props.scriptNumber} Positons`}</p>
        </div>
        <div className="space-x-5">
          <button>
            <AiFillEdit
              className=" text-gray-600 text-xl"
              onClick={handleOpen}
            />
          </button>
          <button>
            <MdDelete
              className="  text-xl text-red-500"
              onClick={() => props.deleteSavedStrategy(user, props.id)}
            />
          </button>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <p className="text-[20px] text-center ">
              Enter New Name for Strategy
            </p>
            <TextField
              label="Strategy Name"
              variant="outlined"
              sx={{ mt: 2, width: "100%" }}
              inputRef={strategyName}
              defaultValue={props.strategyName}
            />
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                props.updateSavedStrategy(
                  user,
                  props.id,
                  strategyName.current.value
                );
                handleClose();
              }}
              type="button"
              className="save-strategy"
              sx={{ mt: 2, py: 1.5, width: "100%" }}
            >
              Save Strategy
            </Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
