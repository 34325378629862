import { useContext, useState } from "react";
import { DataContext } from "./../../Contexts/DataContext";
import { FaSearch, FaRegChartBar, FaChartLine } from "react-icons/fa";
import { FcInfo } from "react-icons/fc";
import { motion } from "framer-motion";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

function AssetSelect({ isHidden }) {
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  const { Price, lastPrice, Coin, infoData } = useContext(DataContext);
  const [show, setShow] = useState(false);
  return (
    <div className="flex relative items-center text-sm md:text-[16px] content-center h-12 gap-2 md:gap-3 shadow-md justify-between md:p-4 px-2 py-4 border-[1px] border-black/30 font-normal bg-white rounded-lg">
      <div className="flex gap-2">
        <div>
          <FaSearch className="mt-1 font-thin" />
        </div>
        <div>
          <p className="italic ">{Coin}</p>
        </div>
      </div>
      <div>
        <div className="flex gap-1">
          <p className="italic font-semibold">{numberWithCommas(Price)}</p>

          <p
            className={`${Math.round(
              (((Price - lastPrice) / ((Price + lastPrice) / 2)) * 100 +
                Number.EPSILON) *
              100
            ) /
              100 >
              0 && "text-green-500"
              } ${Math.round(
                (((Price - lastPrice) / ((Price + lastPrice) / 2)) * 100 +
                  Number.EPSILON) *
                100
              ) /
              100 <
              0 && "text-red-500"
              } font-semibold italic flex items-center mx-1 text-md`}
          >
            {lastPrice ? (
              <>
                {Math.round(
                  (((Price - lastPrice) / ((Price + lastPrice) / 2)) * 100 +
                    Number.EPSILON) *
                  100
                ) /
                  100 >
                  0 ? (
                  <ArrowUpIcon className="w-5 h-5 text-green-500" />
                ) : (
                  <ArrowDownIcon className="w-5 h-5 text-red-500" />
                )}
                {Math.round(
                  (((Price - lastPrice) / ((Price + lastPrice) / 2)) * 100 +
                    Number.EPSILON) *
                  100
                ) / 100}
                %
              </>
            ) : (
              <p className="text-gray-500 italic text-sm">Loading Data....</p>
            )}
          </p>
        </div>
      </div>
      <div>
        <div
          className={`flex gap-3 mx-2 text-3xl text-[#68cae3] ${isHidden && "hidden"
            } `}
        >
          <a
            href={`https://in.tradingview.com/symbols/${Coin}/`}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <FaRegChartBar className="border-[2px] p-1 hover:scale-110 transition-all duration-200 ease-in-out rounded-lg border-[#68cae3]" />
          </a>
          <div className="group cursor-pointer">
            {" "}
            <FcInfo
              onMouseEnter={() => {
                setShow(true);
              }}
            />
          </div>
        </div>
      </div>
      <motion.div
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
          duration: 0.75,
        }}
        className={`absolute ${show ? "inline-block" : "hidden "
          }  transition-all duration-300 ease-in-out  bg-white top-[55px] rounded-t-md  border shadow-md w-full left-0 md:left-[150px] z-[900] italic`}
        onMouseEnter={() => {
          setShow(true);
        }}
        onMouseLeave={() => {
          setShow(false);
        }}
      >
        <div className="bg-[#68cae3] w-full rounded-t-md py-3 px-4 text-white ">
          <div className="w-full flex justify-between">
            <div className="flex items-center w-full h-full gap-1">
              <div className="text-2xl mr-2 text-[#68cae3]">
                <a
                  href={`https://in.tradingview.com/symbols/${Coin}/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <FaChartLine className="border-[2px] p-1 hover:scale-110 transition-all duration-200 ease-in-out rounded-lg text-white border-white" />
                </a>
              </div>
              <div>
                <p className="italic font-semibold">{Coin}</p>
              </div>
              <p className="italic font-semibold">{numberWithCommas(Price)}</p>

              <p
                className={` font-semibold  italic flex items-center mx-1 text-sm w-full h-full`}
              >
                {lastPrice ? (
                  <>
                    {Math.round(
                      (((Price - lastPrice) / ((Price + lastPrice) / 2)) * 100 +
                        Number.EPSILON) *
                      100
                    ) /
                      100 >
                      0 ? (
                      <span>+</span>
                    ) : (
                      <span>-</span>
                    )}
                    {Math.round(
                      (((Price - lastPrice) / ((Price + lastPrice) / 2)) * 100 +
                        Number.EPSILON) *
                      100
                    ) / 100}
                    %
                  </>
                ) : (
                  <p className="text-gray-500 italic text-sm">
                    Loading Data....
                  </p>
                )}
              </p>
            </div>
            <div>
              <XMarkIcon
                fontWeight={700}
                className="border-[2px] w-6 h-6 hover:scale-110 cursor-pointer transition-all duration-200 ease-in-out rounded-lg border-white"
                color="white"
                onClick={() => setShow(false)}
              />
            </div>
          </div>
        </div>
        <div className=" border-t-[1px] border-black/20 py-2 ">
          <div className="flex flex-col">
            <h1 className="text-gray-700 px-4 text-center font-semibold text-md pb-2 ">
              Volume & Price Change
            </h1>
            <div className="text-sm px-4 flex-col space-y-2 md:flex-row flex justify-between italic pb-2 border-b-[1px] border-gray-300 text-gray-600 ">
              <p>
                <span className="text-black font-semibold">Volume BTC </span>:{" "}
                {Math.round(infoData?.volumeBtc)} BTC
              </p>
              <p>
                {" "}
                <span className="text-black font-semibold">Volume </span> :{" "}
                {numberWithCommas(infoData?.volumeUsd)} ${" "}
              </p>
            </div>
            <div className="text-sm px-4 flex w-full  justify-between italic  border-b-[1px] border-gray-300 text-gray-600 py-2  ">
              <div className="border-r-[1px] w-full border-gray-300 basis-2/3">
                <ul className="text-sm text-gray-600 flex flex-col  justify-between w-full">
                  <li>
                    <span className="text-black font-semibold">
                      Future Price
                    </span>{" "}
                    : {numberWithCommas(infoData?.futurePrice)} $
                  </li>
                  <li className="hidden md:inline-block">
                    <span className="text-black font-semibold">
                      Settlement Price
                    </span>{" "}
                    : {numberWithCommas(infoData?.lastPrice)} $
                  </li>
                  <li></li>
                </ul>
              </div>
              <div className="basis-1/2  pl-2">
                <p className="inline-flex w-full justify-center items-center flex-col">
                  <span className="text-black font-semibold">
                    Open Interest
                  </span>{" "}
                  {infoData?.openInterest}
                </p>
              </div>
            </div>
          </div>
          <div className="py-2 flex">
            <div className="basis-1/2 flex  text-sm flex-col gap-3 border-r px-4 ">
              <p className="flex justify-between w-full">
                <span className="text-black font-semibold">Price Change</span> :{" "}
                <span
                  className={`${infoData?.priceChange > 0
                    ? "text-green-500"
                    : "text-red-500"
                    } font-semibold`}
                >
                  {" "}
                  {infoData?.priceChange}
                </span>
              </p>
              <p className="flex justify-between w-full">
                <span className="text-black font-semibold">Price</span> :{" "}
                <span
                  className={`${Math.round(
                    (((Price - lastPrice) / ((Price + lastPrice) / 2)) * 100 +
                      Number.EPSILON) *
                    100
                  ) /
                    100 >
                    0
                    ? "text-green-500"
                    : "text-red-500"
                    } font-semibold`}
                >
                  {" "}
                  {numberWithCommas(Math.round(Price))} ${" "}
                </span>
              </p>
            </div>
            <div className="basis-1/2 flex  text-sm flex-col gap-3 border-r px-4 ">
              <p className="flex justify-between w-full">
                <span className="text-green-500 font-semibold">High</span> :{" "}
                <span className={`text-green-500 font-semibold`}>
                  {" "}
                  {numberWithCommas(infoData?.high)} $
                </span>
              </p>
              <p className="flex justify-between w-full">
                <span className="text-red-500 font-semibold">Low</span> :{" "}
                <span className={`text-red-500 font-semibold`}>
                  {" "}
                  {numberWithCommas(infoData?.low)} $
                </span>
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default AssetSelect;
