export const Svg = ({ name }) => {
  return (
    <>
      {name === "Buy Call" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1107 mb-1"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <path
              stroke="#C22E00"
              strokeWidth="2"
              d="M2.012 31h28.956l7.79-7"
            ></path>
            <path
              stroke="#039855"
              strokeWidth="2"
              d="M39 24L56.577 8.012"
            ></path>
          </g>
        </svg>
      )}

      {name === "Sell Put" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <path stroke="#C22E00" strokeWidth="2" d="M1.968 38L16 24"></path>
            <path
              stroke="#039855"
              strokeWidth="2"
              d="M16 24L32.049 7h25.663"
            ></path>
          </g>
        </svg>
      )}

      {name === "Bull Call Spread" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <path
              stroke="#C22E00"
              strokeWidth="2"
              d="M1 32h14.968l10.127-8"
            ></path>
            <path
              stroke="#039855"
              strokeWidth="2"
              d="M26.095 24L46.049 7H61"
            ></path>
          </g>
        </svg>
      )}
      {name === "Bull Put Spread" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <path
              stroke="#C22E00"
              strokeWidth="2"
              d="M2 39.988h12.009L32 24"
            ></path>
            <path
              stroke="#039855"
              strokeWidth="2"
              d="M32 24l7.618-7h17.349"
            ></path>
          </g>
        </svg>
      )}
      {name === "Call Ratio Back Spread" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <g strokeWidth="2">
              <path stroke="#C22E00" d="M2 29h27l4.798 5L42 25"></path>
              <path stroke="#039855" d="M42 25L56 9.5"></path>
            </g>
          </g>
        </svg>
      )}

      {name === "Bull Condor" && (
        <svg
          width="62"
          height="45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="jss1376"
        >
          <path
            d="M1.5 1v43.237"
            stroke="#DADBDC"
            strokeLinecap="square"
          ></path>
          <path
            d="M1.462 24H61"
            stroke="#DADBDC"
            strokeLinecap="round"
            strokeDasharray="5 5"
          ></path>
          <path
            d="M2 29h24.574l2.171-5M61 29h-3.157l-2.171-5"
            stroke="#C22E00"
            strokeWidth="2"
          ></path>
          <path
            d="M28.746 24l8.251-19H47.42l8.252 19"
            stroke="#039855"
            strokeWidth="2"
          ></path>
        </svg>
      )}
      {name === "Bull Butterfly" && (
        <svg
          width="62"
          height="45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="jss1376"
        >
          <path
            d="M1.5 1v43.237"
            stroke="#DADBDC"
            strokeLinecap="square"
          ></path>
          <path
            d="M1.462 24H61"
            stroke="#DADBDC"
            strokeLinecap="round"
            strokeDasharray="5 5"
          ></path>
          <path
            d="M59 31.5h-7L50.214 24M2 31.5h36.5l1.429-7.5"
            stroke="#C22E00"
            strokeWidth="2"
          ></path>
          <path
            d="M39.929 24L44.5 1.5 50.214 24"
            stroke="#039855"
            strokeWidth="2"
          ></path>
        </svg>
      )}

      {name === "Range Forward" && (
        <svg
          width="62"
          height="45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="jss1376"
        >
          <path
            d="M1.5 1v43.237"
            stroke="#DADBDC"
            strokeLinecap="square"
          ></path>
          <path
            d="M1.462 24H61"
            stroke="#DADBDC"
            strokeLinecap="round"
            strokeDasharray="5 5"
          ></path>
          <path d="M2 32l21-8" stroke="#C22E00" strokeWidth="2"></path>
          <path
            d="M61 15l-20.285 7.764H26.13L23 24"
            stroke="#039855"
            strokeWidth="2"
          ></path>
        </svg>
      )}

      {name === "Buy Put" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <g strokeWidth="2">
              <path stroke="#C22E00" d="M57 30.988H28.045l-7.79-7"></path>
              <path stroke="#039855" d="M20.012 23.988L2.436 8"></path>
            </g>
          </g>
        </svg>
      )}

      {name === "Sell Call" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <g strokeWidth="2">
              <path stroke="#039855" d="M2 17l26.955.012 7.79 7"></path>
              <path stroke="#C22E00" d="M36.988 24.012L54.564 40"></path>
            </g>
          </g>
        </svg>
      )}

      {name === "Bear Call Spread" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 15H61"
            ></path>
            <g strokeWidth="2">
              <path stroke="#039855" d="M2 8.012h12.009l7.482 6.957"></path>
              <path stroke="#C22E00" d="M21.491 14.969L39.618 31h17.349"></path>
            </g>
          </g>
        </svg>
      )}

      {name === "Bear Put Spread" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <g strokeWidth="2">
              <path stroke="#039855" d="M2 8.012h12.009L32 24"></path>
              <path stroke="#C22E00" d="M32 24l7.618 7h17.349"></path>
            </g>
          </g>
        </svg>
      )}

      {name === "Bear Condor" && (
        <svg
          width="62"
          height="45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="jss1376"
        >
          <path
            d="M1.5 1v43.237"
            stroke="#DADBDC"
            strokeLinecap="square"
          ></path>
          <path
            d="M1.462 24H61"
            stroke="#DADBDC"
            strokeLinecap="round"
            strokeDasharray="5 5"
          ></path>
          <path
            d="M61 29H36.426l-2.171-5M2 29h3.157l2.171-5"
            stroke="#C22E00"
            strokeWidth="2"
          ></path>
          <path
            d="M34.254 24L26.003 5H15.579L7.329 24"
            stroke="#039855"
            strokeWidth="2"
          ></path>
        </svg>
      )}
      {name === "Bear Butterfly" && (
        <svg
          width="62"
          height="45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="jss1376"
        >
          <path
            d="M1.5 1v43.237"
            stroke="#DADBDC"
            strokeLinecap="square"
          ></path>
          <path
            d="M1.462 24H61"
            stroke="#DADBDC"
            strokeLinecap="round"
            strokeDasharray="5 5"
          ></path>
          <path
            d="M2 31.5h10.5l1.786-7.5M59 31.5H26L24.571 24"
            stroke="#C22E00"
            strokeWidth="2"
          ></path>
          <path
            d="M24.571 24L20 1.5 14.286 24"
            stroke="#039855"
            strokeWidth="2"
          ></path>
        </svg>
      )}
      {name === "Risk Reversal" && (
        <svg
          width="62"
          height="45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="jss1376"
        >
          <path
            d="M1.5 1v43.237"
            stroke="#DADBDC"
            strokeLinecap="square"
          ></path>
          <path
            d="M1.462 24H61"
            stroke="#DADBDC"
            strokeLinecap="round"
            strokeDasharray="5 5"
          ></path>
          <path
            d="M60 32l-20.494-6.555H28.002L23.552 24"
            stroke="#C22E00"
            strokeWidth="2"
          ></path>
          <path d="M2 17l21.552 7" stroke="#039855" strokeWidth="2"></path>
        </svg>
      )}
      {name === "Short Straddle" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <g strokeWidth="2">
              <path stroke="#C22E00" d="M1 40l7.936-9.012 6.049-7"></path>
              <path stroke="#039855" d="M14.985 23.988L30 7"></path>
              <path stroke="#C22E00" d="M58 40l-7.936-9.012-6.049-7"></path>
              <path stroke="#039855" d="M44.015 23.988L29 7"></path>
            </g>
          </g>
        </svg>
      )}
      {name === "Short Strangle" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <path
              stroke="#C22E00"
              strokeWidth="2"
              d="M2.918 39.5L8.668 24M55.452 39.5L50.44 24"
            ></path>
            <path
              stroke="#039855"
              strokeWidth="2"
              d="M8.668 24l4.576-13h33.284l3.912 13"
            ></path>
          </g>
        </svg>
      )}
      {name === "Short Iron Condor" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <path
              stroke="#C22E00"
              strokeWidth="2"
              d="M1.5 33.5h12.418L17.5 24M57.5 33.5H45.082L41.5 24"
            ></path>
            <path
              stroke="#039855"
              strokeWidth="2"
              d="M17.5 24l6.744-19h11.284L41.5 24"
            ></path>
          </g>
        </svg>
      )}
      {name === "Iron Butterfly" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <path
              stroke="#C22E00"
              strokeWidth="2"
              d="M1.5 33.5h17.418L22.5 24M57.5 33.5H40.082L36.5 24"
            ></path>
            <path
              stroke="#039855"
              strokeWidth="2"
              d="M22.5 24l6.744-19L36.5 24"
            ></path>
          </g>
        </svg>
      )}

      {name === "Batman" && (
        <svg
          width="62"
          height="45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="jss1376"
        >
          <path
            d="M1.5 1v43.237"
            stroke="#DADBDC"
            strokeLinecap="square"
          ></path>
          <path
            d="M1.462 24H61"
            stroke="#DADBDC"
            strokeLinecap="round"
            strokeDasharray="5 5"
          ></path>
          <path
            d="M9.467 24L16 17l6.177 7m18.147 0l6.176-7 6.533 7"
            stroke="#039855"
            strokeWidth="2"
          ></path>
          <path
            d="M2 32l7.467-8M60.5 32l-7.467-8m-30.857 0l1.324 1.5H39l1.324-1.5"
            stroke="#C22E00"
            strokeWidth="2"
          ></path>
        </svg>
      )}
      {name === "Double Plateau" && (
        <svg
          width="62"
          height="45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="jss1376"
        >
          <path
            d="M1.5 1v43.237"
            stroke="#DADBDC"
            strokeLinecap="square"
          ></path>
          <path
            d="M1.462 24H61"
            stroke="#DADBDC"
            strokeLinecap="round"
            strokeDasharray="5 5"
          ></path>
          <path
            d="M23.015 24L17.5 11H11L5.485 24m34.394 0L45 11h7l5.515 13"
            stroke="#039855"
            strokeWidth="2"
          ></path>
          <path
            d="M2 27.5h2L5.485 24M61 27.5h-2L57.515 24m-34.5 0l1.485 3.5h14l1.379-3.5"
            stroke="#C22E00"
            strokeWidth="2"
          ></path>
        </svg>
      )}
      {name === "Jade Lizard" && (
        <svg
          width="62"
          height="45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="jss1376"
        >
          <path
            d="M1.5 1v43.237"
            stroke="#DADBDC"
            strokeLinecap="square"
          ></path>
          <path
            d="M1.462 24H61"
            stroke="#DADBDC"
            strokeLinecap="round"
            strokeDasharray="5 5"
          ></path>
          <path
            d="M61.027 20.588h-13.65L41.562 18.5H29.91l-7.476 5.493"
            stroke="#039855"
            strokeWidth="2"
          ></path>
          <path
            d="M1.95 38.984l20.485-14.99"
            stroke="#C22E00"
            strokeWidth="2"
          ></path>
        </svg>
      )}
      {name === "Reverse Jade Lizard" && (
        <svg
          width="62"
          height="45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="jss1376"
        >
          <path
            d="M1.5 1v43.237"
            stroke="#DADBDC"
            strokeLinecap="square"
          ></path>
          <path
            d="M1.462 24H61"
            stroke="#DADBDC"
            strokeLinecap="round"
            strokeDasharray="5 5"
          ></path>
          <path
            d="M2.007 20.242h17.09l6-2.744h12.059l7.087 6.554"
            stroke="#039855"
            strokeWidth="2"
          ></path>
          <path
            d="M61.11 39.72L44.243 24.051"
            stroke="#C22E00"
            strokeWidth="2"
          ></path>
        </svg>
      )}
      {name === "Put Ratio Spread" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <g strokeWidth="2">
              <path stroke="#039855" d="M56 20H29l-4.798-5L16 24"></path>
              <path stroke="#C22E00" d="M16 24L2 39.5"></path>
            </g>
          </g>
        </svg>
      )}
      {name === "Long Straddle" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <g strokeWidth="2">
              <path stroke="#039855" d="M1 7l7.936 9.012 6.049 7"></path>
              <path stroke="#C22E00" d="M14.985 23.012L30 40"></path>
              <path stroke="#039855" d="M58 7l-7.936 9.012-6.049 7"></path>
              <path stroke="#C22E00" d="M44.015 23.012L29 40"></path>
            </g>
          </g>
        </svg>
      )}
      {name === "Long Iron Butterfly" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 20H61"
            ></path>
            <g strokeWidth="2">
              <path
                stroke="#039855"
                d="M1.5 10.5h17.418L22.5 20M57.5 10.5H40.082L36.5 20"
              ></path>
              <path stroke="#C22E00" d="M22.5 20l6.744 19L36.5 20"></path>
            </g>
          </g>
        </svg>
      )}
      {name === "Long Strangle" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss1376"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <g strokeWidth="2">
              <path
                stroke="#039855"
                d="M2.918 8.5L8.668 24M55.452 8.5L50.439 24"
              ></path>
              <path
                stroke="#C22E00"
                d="M8.668 24l4.575 13h33.285l3.911-13"
              ></path>
            </g>
          </g>
        </svg>
      )}
      {name === "Strip" && (
        <svg
          width="62"
          height="45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="jss1376"
        >
          <path
            d="M1.5 1v43.237"
            stroke="#DADBDC"
            strokeLinecap="square"
          ></path>
          <path
            d="M1.462 24H61"
            stroke="#DADBDC"
            strokeLinecap="round"
            strokeDasharray="5 5"
          ></path>
          <path
            d="M20.77 24L2 12m42.089 12l17.047-5.5"
            stroke="#039855"
            strokeWidth="2"
          ></path>
          <path
            d="M20.77 24l7.821 5 15.498-5"
            stroke="#C22E00"
            strokeWidth="2"
          ></path>
        </svg>
      )}
      {name === "Strap" && (
        <svg
          width="62"
          height="45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="jss1376"
        >
          <path
            d="M1.5 1v43.237"
            stroke="#DADBDC"
            strokeLinecap="square"
          ></path>
          <path
            d="M1.462 24H61"
            stroke="#DADBDC"
            strokeLinecap="round"
            strokeDasharray="5 5"
          ></path>
          <path
            d="M42.366 24l18.77-12M19.047 24L2 18.5"
            stroke="#039855"
            strokeWidth="2"
          ></path>
          <path
            d="M42.366 24l-7.82 5-15.499-5"
            stroke="#C22E00"
            strokeWidth="2"
          ></path>
        </svg>
      )}
      {name === "Put Ratio Back Spread" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss589"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <g strokeWidth="2">
              <path stroke="#C22E00" d="M56 28H29l-4.798 5L16 24"></path>
              <path stroke="#039855" d="M16 24L2 8.5"></path>
            </g>
          </g>
        </svg>
      )}
      {name === "Call Ratio Spread" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss589"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 24H61"
            ></path>
            <g strokeWidth="2">
              <path stroke="#039855" d="M2 20h27l4.798-5L42 24"></path>
              <path stroke="#C22E00" d="M42 24l14 15.5"></path>
            </g>
          </g>
        </svg>
      )}
      {name === "Long Iron Condor" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="45"
          className="jss589"
        >
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#DADBDC"
              strokeLinecap="square"
              d="M1.5 1v43.237"
            ></path>
            <path
              stroke="#DADBDC"
              strokeDasharray="5 5"
              strokeLinecap="round"
              d="M1.462 20H61"
            ></path>
            <g strokeWidth="2">
              <path
                stroke="#039855"
                d="M1.5 10.5h12.418L17.5 20M57.5 10.5H45.082L41.5 20"
              ></path>
              <path
                stroke="#C22E00"
                d="M17.5 20l6.744 19h11.284L41.5 20"
              ></path>
            </g>
          </g>
        </svg>
      )}
    </>
  );
};
