import Strike from "./Strike";
import { Drawer } from "@mui/material";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import AssetSelect from "./SelectorPaneComponents/AssetSelect";
import { StrategyContext } from "../Contexts/StrategyContext";

function StrikeSelection(props) {
  const Drawerstye = {
    right: 0,
    top: 0,
    height: "100%",
    width: { sm: "60%", md: "600px", xs: "80%" },
    padding: "10px",
    boxShadow: "none",
    overflow: "scroll",
  };
  const { isVisible, setVisible } = props;
  const [optionChain, setOptionChain] = useState([]);
  const { StrategyFor } = useContext(StrategyContext);

  useEffect(() => {
    const optionChainData = [];
    async function fetchOptionChain() {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/fetchoptionchain`, {
          asset: props.asset,
          expiry: props.currentExpiry,
        })
        .then((response) => {
          const checkActive = (contract) => {
            if (StrategyFor.length > 0) {
              const found = StrategyFor.some(
                (el) => el.StrikePrice === contract.StrikePrice
              );
              if (found) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          };
          response.data.optionChainRecord.forEach((contract, index) => {
            optionChainData.push(
              <li key={index}>
                <Strike
                  expiry={contract.expiry}
                  symbol={contract.asset}
                  isActive={
                    StrategyFor.length > 0 ? checkActive(contract) : false
                  }
                  callPremium={contract.callPremium}
                  putPremium={contract.putPremium}
                  StrikePrice={contract.StrikePrice}
                  callVega={contract.callVega}
                  putVega={contract.putVega}
                  callRho={contract.callRho}
                  putRho={contract.putRho}
                  callTheta={contract.callTheta}
                  putTheta={contract.putTheta}
                  callGamma={contract.callGamma}
                  putGamma={contract.putGamma}
                  callDelta={contract.callDelta}
                  putDelta={contract.putDelta}
                />
              </li>
            );
          });
        });
      setOptionChain(optionChainData);
    }
    fetchOptionChain();

    // eslint-disable-next-line
  }, [props.currentExpiry, StrategyFor]);
  const submitForm = (event) => {
    setVisible(false);
  };
  return (
    <Drawer
      anchor="left"
      open={isVisible}
      onClose={() => setVisible(false)}
      PaperProps={{ sx: Drawerstye }}
    >
      <div className="flex flex-col md:flex-row justify-between gap-3 p-2  ">
        <div className="basis-4/5 hidden md:inline-block">
          <AssetSelect />
        </div>
        <div className="basis-4/5 inline-block md:hidden">
          <AssetSelect isHidden={true} />
        </div>
        <div className="basis-2/5">
          <div className="flex items-center justify-between h-12 gap-1 p-4 text-center bg-white ">
            <button
              onClick={() => props.clearStrategy()}
              className="bg-white border-[1px] rounded-lg px-4 py-2.5  hover:bg-gray-200"
            >
              Clear
            </button>
            <button
              onClick={submitForm}
              className="bg-white border-[1px] border-[#68cae3] rounded-lg px-4 py-2.5 mx-2  hover:bg-gray-200"
            >
              Done
            </button>
          </div>
        </div>
      </div>
      <div className="border-b-[1px] mb-1.5">
        <select
          name="expiry"
          id="expiry"
          value={props.currentExpiry}
          onChange={(e) => props.setCurrentExpiry(e.target.value)}
          className="mt-1.5 mb-3 ml-5 bg-transparent border-[1px] py-1.5 px-4 rounded-lg w-[120px] border-black/20"
        >
          {props.expiries.map((expiry, index) => (
            <option value={expiry} key={index} className="px-4 py-2 bg-white ">
              {new Date(expiry).getUTCDate() +
                " " +
                props.monthNames[new Date(expiry).getMonth()]}
            </option>
          ))}
        </select>
      </div>

      <div className="flex flex-col mb-0.5 text-[0.8rem] px-2 pb-1">
        <div className="flex">
          <div className="basis-1/3 text-left">
            <p>Call LTP</p>
            <div className="h-1.5 w-6 flex ml-2 rounded-[25%] bg-green-500"></div>
          </div>
          <div className="basis-1/3 text-center">
            <p>Strike</p>
          </div>
          <div className="basis-1/3 text-right">
            <p>Put LTP</p>
            <div className="flex  text-xs justify-center items-center">
              <div className="h-1.5 w-6 flex ml-auto mr-2 rounded-[25%] bg-red-500">
                {" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <motion.ul
          initial={{
            x: -500,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          transition={{
            duration: 1,
          }}
        >
          {optionChain}
        </motion.ul>
      </div>
    </Drawer>
  );
}

export default StrikeSelection;
