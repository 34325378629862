import Scripts from "./Scripts";
import { StrategyContext } from "../../Contexts/StrategyContext";
import { useContext, useEffect, useState } from "react";
import { findGCD } from "../../CalculationFunctions";
import SaveStrategyButton from "./SaveStrategyButton";
import UserLoadedStrategy from "./UserLoadedStrategy";
import axios from "axios";
import { AuthContext } from "../../Contexts/Auth";
import { AiFillWarning } from "react-icons/ai";
import { BsDash } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
// import { DataProvider } from "../../Contexts/DataContext";

function LoadedStrategy(props) {
  const { user } = useContext(AuthContext);
  const [nowStrategy, setNowStrategy] = useState(props.strategy);
  const [strategyLots, setStrategyLots] = useState(1);
  const { StrategyFor, updateStrategy } = useContext(StrategyContext);
  const [counterSaveVisible, setCounterSaveVisible] = useState(true);
  let totalPnl = 0;
  useEffect(() => {
    updateStrategy(nowStrategy);
    const lotsforHCF = [];
    nowStrategy.forEach((item) => {
      if (item.PositionsInstanceId === undefined) {
        lotsforHCF.push(item.lots);
      }
    });
    setStrategyLots(findGCD(lotsforHCF));
    if (
      props.selectUserStrategy &&
      props.selectUserStrategy.ScriptsInstances.length === StrategyFor.length
    ) {
      setCounterSaveVisible(false);
    } else setCounterSaveVisible(true);
    // eslint-disable-next-line
  }, [nowStrategy]);
  if (props.strategy !== nowStrategy) {
    setNowStrategy(props.strategy);
  }

  const deleteScript = async (scriptId, positionId, handleClose) => {
    const headers = {
      Authorization: `Bearer ${user.token}`,
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/deleteScript`,
        {
          positionId: positionId,
          scriptId: scriptId,
        },
        {
          headers: headers,
        }
      )
      .then(() => {
        let newList = StrategyFor.filter((item) => item.id !== scriptId);
        updateStrategy(newList);
        let newScripts = props.selectUserStrategy.ScriptsInstances.filter(
          (item) => item.id !== scriptId
        );
        props.selectUserStrategy.ScriptsInstances = newScripts;
        handleClose();
        if (newList.length === 0) {
          props.setStrategy(null);
        }
      })
      .catch((error) => {
        console.log(error); // Handle Signup Error
      });
  };

  const exitScript = async (exitPrice, positionId, scriptId, handleClose) => {
    const headers = {
      Authorization: `Bearer ${user.token}`,
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/exitScript`,
        {
          positionId: positionId,
          scriptId: scriptId,
          exitPrice: exitPrice,
        },
        {
          headers: headers,
        }
      )
      .then(() => {
        handleClose();
        let newValue = [...StrategyFor];
        newValue.find((item) => item.id === scriptId).exit = exitPrice;
        newValue.find((item) => item.id === scriptId).booked =
          newValue.find((item) => item.id === scriptId).Premium - exitPrice;
        newValue.find((item) => item.id === scriptId).unrealized = 0;
        updateStrategy(newValue);
      })
      .catch((error) => {
        console.log(error); // Handle Signup Error
      });
  };

  const updateLots = (counter) => {
    const updatedLotsStrategy = [];
    StrategyFor.forEach((item) => {
      if (item.PositionsInstanceId === undefined) {
        updatedLotsStrategy.push({
          ...item,
          lots:
            strategyLots === Number(item.lots)
              ? strategyLots + counter
              : Number(item.lots) +
              counter * (Number(item.lots) / strategyLots),
        });
      } else {
        updatedLotsStrategy.push(item);
      }
    });
    updateStrategy(updatedLotsStrategy);
  };

  const selectScript = (id) => {
    let copyStrategy = [...StrategyFor];
    copyStrategy[id].check = !copyStrategy[id].check;
    updateStrategy(copyStrategy);
  };

  const updateScriptPremium = (id, premium) => {
    let copyStrategy = [...StrategyFor];
    copyStrategy[id].Premium = premium;
    updateStrategy(copyStrategy);
  };

  const reverseScriptDirection = (id) => {
    let copyStrategy = [...StrategyFor];
    copyStrategy[id].Direction =
      copyStrategy[id].Direction === "buy" ? "sell" : "buy";
    updateStrategy(copyStrategy);
  };

  const handleRemove = (id) => {
    let newList = StrategyFor.filter((StrategyFor) => StrategyFor.id !== id);
    newList.map((item, index) => (item.id = index));
    updateStrategy(newList);
    if (newList.length === 0) {
      props.setStrategy(null);
    }
  };

  return (
    <div className="md:w-[500px] md:h-[300px] py-2 w-full  md:px-4 px-2 ">
      <div>
        <div className="flex justify-between mb-4">
          <div className="md:basis-1/2">
            <span className="font-semibold inline-block">
              {props.selectUserStrategy === null ? (
                <p className="flex items-center gap-1">
                  New Strategy <AiFillWarning className="text-yellow-500" />
                </p>
              ) : (
                props.selectUserStrategy.strategy
              )}
            </span>
          </div>
          <div className="md:basis-1/2 text-right font-semibold ">
            <button className="underline" onClick={() => props.clearStrategy()}>
              Clear Trades
            </button>
          </div>
        </div>
        <div className="w-full">
          {StrategyFor.map((script, i) =>
            script.PositionsInstanceId === undefined ? (
              <Scripts
                key={script.id}
                id={script.id}
                handleRemove={handleRemove}
                direction={script.Direction === "buy" ? "B" : "S"}
                expiry={script.Expiry.slice(0, 10)}
                strike={script.StrikePrice}
                type={script.Type === "CALL" ? "C" : "P"}
                quantity={script.lots}
                price={script.Premium}
                check={script.check}
                selectScript={selectScript}
                updateScriptLots={props.updateScriptLots}
                updateScriptPremium={updateScriptPremium}
                reverseScriptDirection={reverseScriptDirection}
              />
            ) : (
              ((totalPnl =
                totalPnl + Number(script.booked) + Number(script.unrealized)),
                (
                  <UserLoadedStrategy
                    key={i}
                    id={i}
                    scriptId={script.id}
                    direction={script.Direction === "buy" ? "B" : "S"}
                    expiry={script.Expiry.slice(0, 10)}
                    strike={script.StrikePrice}
                    type={script.Type === "CALL" ? "C" : "P"}
                    quantity={script.lots}
                    price={script.Premium}
                    handleRemove={handleRemove}
                    check={script.check}
                    selectScript={selectScript}
                    currentContractPrice={script.currentPrice}
                    unrealized={script.unrealized}
                    positionId={script.PositionsInstanceId}
                    deleteScript={deleteScript}
                    exitScript={exitScript}
                    booked={script.booked}
                    exit={script.exit}
                  />
                ))
            )
          )}
        </div>
        {!counterSaveVisible ? null : (
          <div className=" flex items-center justify-around w-full px-8">
            <div className="flex ">
              <div className="text-md font-semibold mr-3">Lots Multiplier </div>
              <div className="flex justify-center items-center border-[1px] h-[30px] space-x-4 rounded-md border-black/30 px-1 ">
                <div className="border-r-[1px] border-black/30 pr-2">
                  {" "}
                  <button
                    onClick={() => (strategyLots > 1 ? updateLots(-1) : null)}
                    className="button"
                    type="button"
                  >
                    <BsDash />
                  </button>
                </div>

                <div className="">{strategyLots}</div>
                <div className="border-l-[1px] border-black/30 pl-2">
                  {" "}
                  <button
                    onClick={() => updateLots(1)}
                    className="button"
                    type="button"
                  >
                    <AiOutlinePlus />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-between items-center mt-6 pb-6 ">
          <button
            class="rounded-lg px-4 py-1.5 border-2 border-[#68cae3] text-[#68cae3] hover:bg-[#68cae3] hover:text-white duration-300"
            onClick={() => props.setVisible(true)}
          >
            Add Script
          </button>

          {!counterSaveVisible ? null : (
            <SaveStrategyButton
              fetchSavedStrategy={props.fetchSavedStrategy}
              selectUserStrategy={props.selectUserStrategy}
              setCounterSaveVisible={setCounterSaveVisible}
            />
          )}

          {props.selectUserStrategy === null ? null : (
            <div className="flex">
              <div>
                <p>
                  Total PNL :{" "}
                  <i className="font-semibold">{totalPnl.toFixed(2)}</i>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoadedStrategy;
